import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loading";
import machineService from "../../../services/MachineService";
import { Grid } from "@mui/material";
import styles from "./styles.module.css";
import ButtonGray from "../../utils/CustomFields/ButtonGray";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddMachineDialog from "../../popups/AddMachineDialog";
import WarningMsg from "../../utils/WarningMsg/WarningMsg";

function LabelScale() {
  const [Loading, setLoading] = useState(false);
  const [machines, setMachines] = useState([]);
  const [addMachine, setAddMachine] = useState(false);

  const closeAddMachineDialogHandler = () => {
    setAddMachine(false);
  };
  const openAddMachineDialogHandler = () => {
    setAddMachine(true);
  };

  const getMachines = async () => {
    setLoading(true);
    try {
      const resp = await machineService.getMachines();
      setMachines(resp);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMachines();
  }, []);

  return (
    <>
      <Grid container className={styles.contentWrapper}>
        <Loader isOpen={Loading} />
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <h2>Label Scale</h2>
          <div>
            <ButtonGray className={styles.blueBtn}>Stop server</ButtonGray>
            <ButtonGray
              className={styles.btnStyle}
              onClick={openAddMachineDialogHandler}
            >
              <NoteAddIcon style={{ paddingRight: "5px" }} />
              Add new machine
            </ButtonGray>
          </div>
        </Grid>
        <Grid item xs={12} marginTop={2} className={styles.machineContainer}>
          {machines.length > 0 && (
            <Grid container rowGap={2}>
              {machines.map((machine) => (
                <Grid
                  item
                  xs={12}
                  key={machine.id}
                  className={styles.machineItem}
                >
                  <div className={styles.machine}>
                    <h5>0232fe235tg0</h5>
                    {machine?.active ? (
                      <h6 className={styles.online}>Online</h6>
                    ) : (
                      <h6 className={styles.offline}>Offline</h6>
                    )}
                  </div>
                  <div className={styles.machine}>
                    <div className={styles.edit}>
                      <EditIcon className={styles.icon} />
                    </div>
                    <div className={styles.delete}>
                      <DeleteIcon className={styles.icon} />
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
          {(!machines || !machines.length) && (
            <WarningMsg
              title="No Machines"
              message={`You don't have a machine, please add`}
              btnName="Add New Machine"
              onClickHandler={openAddMachineDialogHandler}
            />
          )}
        </Grid>
      </Grid>
      {addMachine && (
        <AddMachineDialog
          closeDialog={closeAddMachineDialogHandler}
          isOpen={addMachine}
        />
      )}
    </>
  );
}

export default LabelScale;
