import {
  Grid,
  InputAdornment,
  IconButton,
  MenuItem,
  Divider,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import CategoriesNavebar from "../../utils/CategoriesNavebar/CategoriesNavebar";
import Loader from "../../utils/Loading/index";
import styles from "./styles.module.css";
import TextFieldBGWhite from "../../utils/CustomFields/TextFieldBGWhite";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { ORDER_YTPES } from "../../../const";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import ItemView from "../../utils/ItemView/ItemView";
import SalesManageSession from "../../utils/SalesManageSession/SalesManage";
import Cart from "../../utils/Cart/Cart";
import CartItemEditDialog from "../../popups/CartItemEditDialog";
import ProductContext from "../../../context/ProductContext";
import BillEditDialog from "../../popups/BillEditDialog";
import RupieeIcon from "../../../images/rupees_icon.png";
import CartContext from "../../../context/CartContext";

function Sales() {
  const {
    selectedCategory,
    items,
    categories,
    isLoading: loadingProductContext,
  } = useContext(ProductContext);
  const { handleAddItemToSale, cart, handleSaleMoveToCart } =
    useContext(CartContext);

  const [orderType, setOrderType] = useState(ORDER_YTPES[0]);
  const [selectedItems, setSelectdedItems] = useState([]);
  const [cartItemEdit, setCartItemEdit] = useState(null);
  const [invoiceEdit, setInvoiceEdit] = useState(false);
  const [outoffStockItems, setOutoffStockItems] = useState(0);

  const cartItemEditHandler = (data) => {
    setCartItemEdit(data);
  };

  const invoiceEditHandler = () => {
    setInvoiceEdit(true);
  };

  useEffect(() => {
    const filteredItems = items.filter(
      (item) => item?.category === selectedCategory.id
    );
    setOutoffStockItems(filteredItems.filter((i) => i.stock < 1));
    setSelectdedItems(filteredItems);
  }, [selectedCategory, items]);

  return (
    <>
      <Grid container columnGap={2} marginLeft={0}>
        <Loader isOpen={loadingProductContext} />
        <Grid item className={styles.navbar}>
          <CategoriesNavebar categories={categories} />
        </Grid>
        <Grid item className={styles.contentArea}>
          <Grid container rowSpacing={2} style={{ height: "100%" }}>
            <Grid item xs={12}>
              <Grid container columnSpacing={1}>
                <Grid item xs={6}>
                  <TextFieldBGWhite
                    label="search category and menu"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchRoundedIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
                  <TextFieldBGWhite
                    select
                    name="order-type"
                    size="small"
                    value={orderType}
                    style={{ maxWidth: "150px", width: "150px" }}
                    onChange={(event) => {
                      setOrderType(event.target.value);
                    }}
                  >
                    {ORDER_YTPES.map((orderType) => (
                      <MenuItem value={orderType}>{orderType}</MenuItem>
                    ))}
                  </TextFieldBGWhite>
                  <ButtonBlue
                    style={{ marginLeft: 12 }}
                    size="small"
                    onClick={handleSaleMoveToCart}
                  >
                    <ShoppingCartIcon
                      style={{ fontSize: 16, marginRight: "8px" }}
                    />
                    <span style={{ paddingTop: 6 }}>
                      {cart.length < 9 ? `0${cart.length}` : cart.length}
                    </span>
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      style={{ backgroundColor: "white", marginLeft: "8px" }}
                    />
                    <AddIcon fontSize="small" />
                  </ButtonBlue>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <h2>{selectedCategory?.name.toLowerCase()}</h2>
              <div>
                <span className={styles.warrningText}>
                  {outoffStockItems.length} items out of stock
                </span>
                <span className={styles.infoText} style={{ marginLeft: 12 }}>
                  {selectedItems.length} Products
                </span>
              </div>
            </Grid>
            <Grid item xs={12} className={styles.itemListWrapper}>
              <Grid container spacing={1}>
                {selectedItems.map((item) => (
                  <ItemView
                    item={item}
                    subContent={
                      <>
                        <img
                          src={RupieeIcon}
                          className={styles.rupeeIcon}
                          alt="rupiee"
                        />
                        <span>{`${item?.price}/kg, MRP: ${
                          item?.mrp !== 0 ? item?.mrp : item?.price
                        }`}</span>
                      </>
                    }
                    clickItemHandler={handleAddItemToSale}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SalesManageSession />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={styles.cartWrapper}>
          <Cart
            cartItemEdit={cartItemEditHandler}
            invoiceEditHandler={invoiceEditHandler}
          />
        </Grid>
      </Grid>

      {cartItemEdit && (
        <CartItemEditDialog
          selectedCartItem={cartItemEdit}
          handleClose={() => {
            setCartItemEdit(null);
          }}
        />
      )}

      {invoiceEdit && (
        <BillEditDialog
          handleClose={() => {
            setInvoiceEdit(false);
          }}
        />
      )}
    </>
  );
}

export default Sales;
