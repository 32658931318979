import { Grid } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import ButtonBlue from "../CustomFields/ButtonBlue";

function WarningMsg({ title, message, btnName, onClickHandler }) {
  return (
    <Grid className={styles.contentWrapper} rowGap={2}>
      <Grid>
        <h2 style={{ textTransform: "capitalize" }}>{title}</h2>
      </Grid>
      <Grid>
        <h4 style={{ color: "#8b8b8b" }}>{message}</h4>
      </Grid>
      <Grid mt={2}>
        <ButtonBlue
          fullWidth
          style={{ padding: "8px 40px" }}
          onClick={() => onClickHandler()}
        >
          {btnName}
        </ButtonBlue>
      </Grid>
    </Grid>
  );
}

export default WarningMsg;
