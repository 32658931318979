import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { STORAGE_KEYS } from "../../const";
import { CONTACT_NUMBER } from "../../const";

function SubscriptionEndDialog({ subscriptionEndDate, handleClose }) {
  const shop_name = localStorage.getItem(STORAGE_KEYS.SHOP_NAME);

  const contactHandler = () => {
    const url = `https://api.whatsapp.com/send?phone=${CONTACT_NUMBER}&text=${encodeURIComponent(
      "Need help on Sanoft Android POS"
    )}`;
    window.open(url, "_blank");
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Your subscription has ended"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your subscription has ended for shop {shop_name} on{" "}
          {subscriptionEndDate}. Please renew your subscription to continue
          using the POS system.-{" "}
          <strong>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://api.whatsapp.com/send?phone=${CONTACT_NUMBER}&text=${encodeURIComponent(
                "Need help on YESCLOUD POS"
              )}`}
            >
              Contact Us
            </a>
          </strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
        <Button onClick={contactHandler} autoFocus>
          Contact Us
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubscriptionEndDialog;
