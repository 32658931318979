import React from "react";
import styles from "./ErrorPage.module.css";
import { ROUTES } from "../../../const";
import { Grid } from "@mui/material";
import ButtonLightBlue from "../../utils/CustomFields/ButtonLightBlue";
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate();
  const onClickHomeButton = () => {
    navigate(ROUTES.PRODUCTS);
  };
  return (
    <>
      <Grid container rowSpacing={5} className={styles.textArea}>
        <Grid item xs={12} display="flex" justifyContent={"center"}>
          <span className={styles.head1}>Page Not Found!</span>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent={"center"}>
          <ButtonLightBlue
            variant="contained"
            onClick={onClickHomeButton}
            fullWidth
          >
            Go Home
          </ButtonLightBlue>
        </Grid>
      </Grid>
    </>
  );
}

export default ErrorPage;
