import React, { useEffect, useState } from "react";
import { Grid, MenuItem } from "@mui/material";
import styles from "./styles.module.css";
import TextFieldBGWhite from "../../utils/CustomFields/TextFieldBGWhite";
import { INVOICE_PRINTER, BARCODE_PRINTER } from "../../../const";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";

function Printers() {
  const [invoicePrinter, setInvoicePrinter] = useState({});
  const [barcodePrinter, setBarcodePrinter] = useState({});
  const [usbPrinters, setUsbPrinters] = useState([]);

  const getUsbPrinters = async () => {
    try {
      setUsbPrinters([])
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUsbPrinters()
  }, [])

  return (
    <Grid container className={styles.contentWrapper} mt={0} rowGap={2}>
      <Grid item xs={12}>
        <h3>KOT</h3>
      </Grid>
      <Grid item xs={12} className={styles.dataWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid className={styles.container}>
              <h3>Invice printer</h3>
              <h5>Printer type</h5>
              <TextFieldBGWhite
                select
                name="invoice-printer"
                size="small"
                value={invoicePrinter?.type}
                fullWidth
                onChange={(event) => {
                  setInvoicePrinter({ type: event.target.value });
                }}
              >
                {INVOICE_PRINTER["type"].map((type) => (
                  <MenuItem
                    style={{ textTransform: "capitalize" }}
                    value={type}
                  >
                    {type}
                  </MenuItem>
                ))}
              </TextFieldBGWhite>

              {invoicePrinter?.type === "USB" && (
                <>
                  <h5>USB Printers</h5>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={8} lg={9}>
                      <TextFieldBGWhite
                        select
                        name="barcode-printer"
                        label={
                          usbPrinters.length
                            ? "Select printer type"
                            : "No device found"
                        }
                        size="small"
                        fullWidth
                        value={invoicePrinter?.usbPrinter}
                        onChange={(event) => {
                          setInvoicePrinter({
                            ...invoicePrinter,
                            usbPrinter: event.target.value,
                          });
                        }}
                      >
                        {usbPrinters.map((type) => (
                          <MenuItem
                            style={{ textTransform: "capitalize" }}
                            value={type}
                          >
                            {type}
                          </MenuItem>
                        ))}
                      </TextFieldBGWhite>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <ButtonBlue
                        fullWidth
                        style={{
                          padding: "9px 0px",
                        }}
                      >
                        Reload
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                  <h5>Invoice Format</h5>
                  <TextFieldBGWhite
                    select
                    name="barcode-printer"
                    size="small"
                    value={invoicePrinter?.invoiceFormat}
                    fullWidth
                    onChange={(event) => {
                      setInvoicePrinter({
                        ...invoicePrinter,
                        invoiceFormat: event.target.value,
                      });
                    }}
                  >
                    {INVOICE_PRINTER["invoiceFormat"].map((type) => (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        value={type}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </TextFieldBGWhite>
                  <h5>KOT Format</h5>
                  <TextFieldBGWhite
                    select
                    name="barcode-printer"
                    size="small"
                    value={barcodePrinter?.kotFormat}
                    fullWidth
                    onChange={(event) => {
                      setInvoicePrinter({
                        ...invoicePrinter,
                        kotFormat: event.target.value,
                      });
                    }}
                  >
                    {INVOICE_PRINTER["kotFomat"].map((type) => (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        value={type}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </TextFieldBGWhite>
                  <Grid container columnSpacing={2} mt={5}>
                    <Grid item xs={6}>
                      <ButtonBlue fullWidth style={{ padding: "9px 0px" }}>
                        Test Printer
                      </ButtonBlue>
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonBlue fullWidth style={{ padding: "9px 0px" }}>
                        Save
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid className={styles.container}>
              <h3>Barcode printer</h3>
              <h5>Printer type</h5>
              <TextFieldBGWhite
                select
                name="barcode-printer"
                size="small"
                value={barcodePrinter?.type}
                fullWidth
                onChange={(event) => {
                  setBarcodePrinter({ type: event.target.value });
                }}
              >
                {BARCODE_PRINTER["type"].map((type) => (
                  <MenuItem
                    style={{ textTransform: "capitalize" }}
                    value={type}
                  >
                    {type}
                  </MenuItem>
                ))}
              </TextFieldBGWhite>
              {barcodePrinter?.type === "USB" && (
                <>
                  <h5>USB Printers</h5>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={8} lg={9}>
                      <TextFieldBGWhite
                        select
                        name="barcode-printer"
                        label={
                          usbPrinters.length
                            ? "Select printer type"
                            : "No device found"
                        }
                        size="small"
                        fullWidth
                        value={barcodePrinter?.usbPrinter}
                        onChange={(event) => {
                          setBarcodePrinter({
                            ...barcodePrinter,
                            usbPrinters: event.target.value,
                          });
                        }}
                      >
                        {usbPrinters.map((type) => (
                          <MenuItem
                            style={{ textTransform: "capitalize" }}
                            value={type}
                          >
                            {type}
                          </MenuItem>
                        ))}
                      </TextFieldBGWhite>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <ButtonBlue
                        fullWidth
                        style={{
                          padding: "9px 0px",
                        }}
                      >
                        Reload
                      </ButtonBlue>
                    </Grid>
                  </Grid>

                  <h5>Barcode Format</h5>
                  <TextFieldBGWhite
                    select
                    name="barcode-printer"
                    size="small"
                    value={barcodePrinter?.invoiceFormat}
                    fullWidth
                    onChange={(event) => {
                      setBarcodePrinter({
                        ...barcodePrinter,
                        invoiceFormat: event.target.value,
                      });
                    }}
                  >
                    {BARCODE_PRINTER["barcodeFormat"].map((type) => (
                      <MenuItem
                        style={{ textTransform: "capitalize" }}
                        value={type}
                      >
                        {type}
                      </MenuItem>
                    ))}
                  </TextFieldBGWhite>
                  <Grid container columnSpacing={2} mt={5}>
                    <Grid item xs={6}>
                      <ButtonBlue fullWidth style={{ padding: "8px 0px" }}>
                        Test Printer
                      </ButtonBlue>
                    </Grid>
                    <Grid item xs={6}>
                      <ButtonBlue fullWidth style={{ padding: "8px 0px" }}>
                        Save
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Printers;
