import React, { useState } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";

function MoreInfo() {
  const [logo, setLogo] = useState();
  const [isDragging, setIsDragging] = useState(false);

  const uploadLogo = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      setLogo(files[0]);
    }
  };

  const getLogoSrc = () => {
    if (logo) {
      return URL.createObjectURL(logo);
    }
  };

  return (
    <Grid container className={styles.contentWrapper} mt={0} rowGap={2}>
      <Grid item xs={12}>
        <h3>Features</h3>
      </Grid>
      <Grid item xs={12} className={styles.dataWrapper}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={12} md={6}>
            <Grid className={styles.container}>
              <h6>Username</h6>
              <h4>Sanoft technologies PVT LTD</h4>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid className={styles.container}>
              <h6>Version</h6>
              <h4>2.5.3</h4>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h5 className={styles.subHeader}>Shop logo</h5>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              className={`${styles.logoContainer} ${isDragging && styles.dragging}`}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {logo && (
                <img
                  src={getLogoSrc()}
                  alt="logo"
                  id="logo"
                  className={styles.logo}
                />
              )}
              <input
                type="file"
                className={styles.uploader}
                onChange={uploadLogo}
              />
              {!logo && (
                <Grid textAlign={"center"}>
                  <h5>
                    Drop your logo here, or <span>browse</span>
                  </h5>
                  <h6>Supports: jpg, png</h6>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MoreInfo;
