import React, { useState } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import ButtonBlue from "../utils/CustomFields/ButtonBlue";
import TextFieldBGGray from "../utils/CustomFields/TextFieldBGGray";

function AddCustomer(props) {
  const { togglePaymentTab, addPaymentToCustomer, currentBalance } = props;
  const [amount, setAmount] = useState();
  const [note, setNote] = useState("");
  const [finalBalance, setFinalBalance] = useState();

  const addPayment = () => {
    let data = { note };
    if (amount < 0) data.debit = Math.abs(amount);
    else data.credit = amount * 1;
    addPaymentToCustomer(data);
  };

  const calculateByAmount = (amt) => {
    let balance = currentBalance + Number(amt);
    setAmount(amt);
    setFinalBalance(balance);
  };

  const calculateByFinalBalance = (balance) => {
    let amt = balance - currentBalance;
    setAmount(amt);
    setFinalBalance(balance);
  };

  return (
    <div className={styles.popUp}>
      <Grid
        container
        className={styles.contentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Grid item xs={12} mb={2}>
          <h3>Add Payment</h3>
        </Grid>
        <Grid item xs={12} className={styles.contentArea} mb={2}>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Balance</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                size="small"
                fullWidth
                value={currentBalance}
                disabled
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Amount</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter amount"
                size="small"
                fullWidth
                value={amount}
                onChange={(event) => calculateByAmount(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Note</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter note"
                size="small"
                fullWidth
                value={note}
                onChange={(event) => setNote(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Final balance</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                size="small"
                fullWidth
                value={finalBalance}
                onChange={(e) => calculateByFinalBalance(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonBlue
            style={{ padding: "7px 12px" }}
            className={styles.buttonGray}
            onClick={togglePaymentTab}
          >
            Cancel
          </ButtonBlue>
          <ButtonBlue
            style={{ padding: "7px 12px" }}
            disabled={amount === '' || note === ''}
            onClick={addPayment}
          >
            Submit
          </ButtonBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddCustomer;
