import RoundOff from '../components/utils/RoundOff';
import TaxManager from './TaxManager';

class CartManager {
  getItemAmount = (cartItem) => {
    return RoundOff(cartItem.qty * cartItem.rate);
  };

  getItemAmountWithDiscount = (cartItem) => {
    let amount = this.getItemAmount(cartItem);
    if (cartItem.discount) {
      amount -= (amount * cartItem.discount) / 100;
    }
    return RoundOff(amount);
  };

  getItemAmountWithDiscountAndTaxes = (cartItem) => {
    let amount = this.getItemAmountWithDiscount(cartItem);
    amount += TaxManager.getItemCess(cartItem);
    amount += TaxManager.getItemTax(cartItem);
    return RoundOff(amount);
  };

  getItemAmountWithDiscountAndTaxesUsingCartItem = (cartItem) => {
    let amount = this.getItemAmountWithDiscount(cartItem);
    amount += cartItem.cess;
    amount += cartItem.tax;
    return RoundOff(amount);
  };

  getTotalWithDiscountAndTaxes = (cartItems) => {
    return (cartItems || [])
      .map((cartItem) => this.getItemAmountWithDiscountAndTaxesUsingCartItem(cartItem))
      .reduce((acc, amount) => acc + amount, 0);
  };

  getItemDiscountAmount = (cartItem) => {
    return RoundOff(((cartItem.rate * cartItem.discount) / 100) * cartItem.qty);
  };

  getTotalAmount = (cartItems) => {
    return RoundOff(
      (cartItems || []).map((cartItem) => this.getItemAmount(cartItem)).reduce((acc, amount) => acc + amount, 0)
    );
  };

  getTotalDiscountAmount = (cartItems) => {
    return RoundOff(cartItems.reduce((acc, cartItem) => acc + cartItem.discountAmount, 0));
  };

  getTotalCessAmount = (cartItems) => {
    return RoundOff((cartItems || []).reduce((acc, cartItem) => acc + cartItem.cess, 0));
  };

  getTotalTaxAmount = (cartItems) => {
    return RoundOff((cartItems || []).reduce((acc, cartItem) => acc + cartItem.tax, 0));
  };

  getUpdadingItem = (items, cartItem) => {
    return items.find((i) => i.id === cartItem.id);
  };

  getCartItemIndex = (cartItems, cartItem) => {
    return cartItems.findIndex((i) => i.id === cartItem.id);
  };

  getCartItemData = (updatingItem, updatingCartItem, qty = null, discount = null) => {
    const rate = TaxManager.getItemUnitRate(updatingItem, updatingCartItem);
    const quantity = qty ?? updatingCartItem.qty;
    const itemDiscount = discount ?? updatingCartItem?.discount ?? 0;
    updatingItem.qty = quantity;
    updatingItem.discount = itemDiscount;
    updatingItem.rate = rate;
    const cess = TaxManager.getItemCess(updatingItem);
    const tax = TaxManager.getItemTax(updatingItem);
    const discountAmount = this.getItemDiscountAmount(updatingItem);
    const itemAmount = this.getItemAmountWithDiscount(updatingItem);
    const payable = this.getItemAmountWithDiscountAndTaxes(updatingItem);

    return {
      ...updatingCartItem,
      cess,
      tax,
      payable,
      itemAmount,
      qty: quantity,
      rate,
      discount: itemDiscount,
      discountAmount,
    };
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CartManager();
