import React from "react";
import styles from "./styles.module.css";
import { Grid, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DataTable from "../utils/DataTable";
import DateTimeHelpers from "../../helpers/DateTimeHelpers";

function CustomerSaleDialog({ closeDialog, saleData }) {
  const items = saleData?.items || [];
  const columnHeader = [
    {
      id: "item.name",
      label: "Name",
      type: "text",
    },
    {
      id: "quantity",
      label: "Quantity",
      type: "floatQuantity",
    },
    {
      id: "rate",
      label: "Price",
      type: "floatAmount",
    },
    {
      id: "tax",
      label: "Tax",
      type: "text",
    },
    {
      id: "payable",
      label: "Total",
      type: "floatAmount",
    },
  ];
  const getDate = (time) => {
    const date = new Date(time);
    return DateTimeHelpers.convertDateToIsoDMY(date);
  };
  const getTime = (time) => {
    const date = time.split("T")[1].split(".")[0];
    return DateTimeHelpers.formatServerTimeSegment(date);
  };
  return (
    <div className={styles.popUp}>
      <Grid
        container
        className={styles.billContentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
        rowSpacing={1.5}
      >
        <Grid item xs={12} mb={2} display="flex" justifyContent="space-between">
          <h3>Sale Details</h3>
          <CloseRoundedIcon onClick={closeDialog} className={styles.icon} />
        </Grid>
        <Grid item xs={12} className={styles.dataContainer}>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Bill no:</h5>
              <h5>{saleData?.bill_no}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Date</h5>
              <h5>{getDate(saleData?.time)}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Time</h5>
              <h5>{getTime(saleData?.time)}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>ID</h5>
              <h5>{saleData?.id}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Tax</h5>
              <h5>{saleData?.tax || 0}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Additional charges</h5>
              <h5>{saleData?.cess || 0}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Discount</h5>
              <h5 className={styles.heighLite}>{saleData?.discount || 0}</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Payable</h5>
              <h5 className={styles.heighLite}>{saleData?.payable || 0}</h5>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h4>Items:</h4>
        </Grid>
        <Grid item xs={12} className={styles.tableContainer}>
          <DataTable columns={columnHeader} rows={items} />
        </Grid>
      </Grid>
    </div>
  );
}

export default CustomerSaleDialog;
