import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import styles from "./styles.module.css";
import SalesPersonService from "../../../services/SalespersonService";
import Loader from "../../utils/Loading";

function SalesPerson() {
  const [salespersons, setSalespersons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getSalespersons = async () => {
    setIsLoading(true);
    try {
      const resp = await SalesPersonService.getSalesPersons();
      setSalespersons(resp);
    } catch (error) {
      console.log("Error: ", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSalespersons();
  }, []);
  
  return (
    <Grid container className={styles.contentWrapper} mt={0} rowGap={2}>
      <Loader isOpen={isLoading} />
      <Grid item xs={12}>
        <h3>Salesperson</h3>
      </Grid>
      <Grid item xs={12} className={styles.dataWrapper}>
        <Grid container spacing={3}>
          {salespersons.map((salesperson) => (
            <Grid item xs={12} md={6}>
              <Grid className={styles.container}>
                <h4>{salesperson?.name}</h4>
                <h5>Username is {salesperson?.username}</h5>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SalesPerson;
