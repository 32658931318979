import React from "react";
import {
  CardMedia,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import styles from "./styles.module.css";

export default function DataTable(props) {
  const columns = props.columns || [];
  const rows = props.rows || [];
  const rowKey = props.rowKey || "id";

  const getValueForCell = (data, key) => {
    if (key.includes(".")) {
      let value = data;
      key.split(".").forEach((segment) => {
        if (value) {
          value = value[segment];
        } else {
          return undefined;
        }
      });
      return value;
    } else if (key.includes("-")) {
      return key
        .split("-")
        .map((k) => data[k])
        .join("-");
    } else {
      return data[key];
    }
  };

  const getRowStyle = (row) => {
    if (props.deactiveRow && row?.is_active === false) {
      return { backgroundColor: "rgba(245, 39, 39, 0.2)" };
    }
    return;
  };

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table aria-label="simple table" size="medium">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                className={styles.columnHead}
                align={column.align}
                sx={{ padding: "10px" }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={getValueForCell(row, rowKey)}
              style={getRowStyle(row)}
              onClick={() => props.rowClickListener?.(row)}
            >
              {columns.map((column) => {
                const rawValue = getValueForCell(row, column.id);
                let value = "-dff";
                try {
                  switch (column.type) {
                    case "text":
                      value = String(rawValue ?? "-");
                      break;
                    case "floatQuantity":
                      value = isNaN(rawValue) ? rawValue : rawValue.toFixed(3);
                      break;
                    case "floatAmount":
                      value =
                        !rawValue || isNaN(rawValue)
                          ? String(rawValue ?? "-")
                          : rawValue.toFixed(2);
                      break;
                    case "callback":
                      value = column.viewRender(row);
                      break;
                    case "button":
                      value = (
                        <Button
                          onClick={(e) => {
                            column.clickHandler(row);
                            e.stopPropagation();
                          }}
                          variant="contained"
                          size="small"
                          color={column?.color ?? "secondary"}
                        >
                          {column.title || "Button"}
                        </Button>
                      );
                      break;
                    case "image":
                      value = (
                        <CardMedia
                          size="small"
                          image={row[column.id]}
                          className={styles.image}
                        />
                      );
                      break;
                    default:
                      value = `invalid-type '${column.type}'`;
                  }
                } catch (error) {
                  value = error.message;
                  console.error(error);
                }
                return (
                  <TableCell
                    key={column.id}
                    style={{
                      ...(column.type === "button" ? { maxWidth: "10px" } : {}),
                      ...(column?.balanceIndicator && {
                        color: column.balanceIndicator(value),
                      },
                      { padding: "10px" }),
                    }}
                    align={column.align}
                  >
                    {value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {props.summary && (
            <TableRow>
              {columns.map((column) => {
                const value = props.summary[column.id];
                return (
                  <TableCell key={column.id}>
                    <b>{value ?? ""}</b>
                  </TableCell>
                );
              })}
            </TableRow>
          )}
          {props.children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
