import React from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import ButtonBlue from "../utils/CustomFields/ButtonBlue";
import TextFieldBGGray from "../utils/CustomFields/TextFieldBGGray";
import CustomCheckbox from "../utils/CustomFields/CustomCheckbox";

function AddMachineDialog({ closeDialog }) {
  return (
    <div className={styles.popUp} onClick={closeDialog}>
      <Grid
        container
        className={styles.contentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Grid item xs={12} mb={2}>
          <h3>New Machine</h3>
        </Grid>
        <Grid item xs={12} className={styles.contentArea} mb={2}>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Machine name</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter machine name"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Mac ID</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray label="Enter mac ID" size="small" fullWidth />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Machine number</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter machine number"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Bill prifix</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter bill prefix"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container marginTop={2}>
            <Grid item className={styles.checkbox}>
              <CustomCheckbox checked={true} />
              <h5>Support scales</h5>
            </Grid>
            <Grid item className={styles.checkbox}>
              <CustomCheckbox checked={false} />
              <h5>Support unicode</h5>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ButtonBlue fullWidth style={{ padding: 8 }}>
            Add New Machine
          </ButtonBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddMachineDialog;
