import React, { useContext } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import ButtonGray from "../CustomFields/ButtonGray";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ProductContext from "../../../context/ProductContext";

function CartItem({ item, editItem, removeItem, addItem }) {
  const { items } = useContext(ProductContext);
  const product = items.find((i) => i.id === item.id);
  return (
    <Grid item xs={12} className={styles.cartItem}>
      <Grid container>
        <Grid item xs={3} className={styles.cartItemImg}>
          <img src={product?.image} alt="img" className={styles.cartItemImg} />
        </Grid>
        <Grid item xs={7} className={styles.cartItemInfo}>
          <h5>{item?.name}</h5>
          <span>{product?.note}</span>
          <div>
            <CurrencyRupeeIcon className={styles.icon} />
            <h5>{item?.rate}</h5>
          </div>
          <div className={styles.addCartWrapper}>
            <ButtonGray onClick={() => removeItem(item)}>
              <RemoveIcon className={styles.smallIcon} />
            </ButtonGray>
            <h5>{item?.qty}</h5>
            <ButtonGray onClick={() => addItem(item)}>
              <AddIcon className={styles.smallIcon} />
            </ButtonGray>
          </div>
        </Grid>
        <Grid item xs={2} className={styles.cartItemEdit}>
          <div style={{ display: "flex" }}>
            <CurrencyRupeeIcon className={styles.rupieeIcon} />
            <h5>{item?.itemAmount}</h5>
          </div>
          <ButtonGray onClick={() => editItem(item)}>
            <BorderColorRoundedIcon className={styles.smallIcon} />
          </ButtonGray>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CartItem;
