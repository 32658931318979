import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./styles.module.css";

function PosLayout() {
  return (
    <>
      <div className={styles.ContentsWrapper}>
        <Outlet />
      </div>
    </>
  );
}

export default PosLayout;
