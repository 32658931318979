import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import styles from "./styles.module.css";
import ProductsManage from "../../utils/ProductsManage/ProductsManage";
import TextFieldBGGray from "../../utils/CustomFields/TextFieldBGGray";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ButtonGray from "../../utils/CustomFields/ButtonGray";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import Loader from "../../utils/Loading";
import ItemService from "../../../services/ItemService";
import ItemView from "../../utils/ItemView/ItemView";
import RupieeIcon from "../../../images/rupees_icon.png";
import KitchenIcon from "@mui/icons-material/Kitchen";
import EastIcon from "@mui/icons-material/East";

function Products() {
  const { categoryId } = useParams();
  const [Loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const getItems = useCallback(async () => {
    setLoading(true);
    try {
      const items = await ItemService.getItems();
      const selectedItems = items.filter(
        (item) => item?.category === Number(categoryId)
      );
      selectedItems.forEach((item) => {
        item.name = item.name.toLowerCase();
      });
      setItems(selectedItems);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }, [categoryId]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <>
      <Grid container style={{ height: "100%", marginTop: 0 }} rowSpacing={2}>
        <Loader isOpen={Loading} />
        <Grid item xs={12} className={styles.contentWrapper}>
          <Grid container height="100%">
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <div className={styles.title}>
                <h2>Categories</h2>
                <div style={{ display: "flex"}}>
                  <div className={styles.headSec}>
                    <h5 style={{ color: "#8b8b8b" }}>Categories</h5>{" "}
                    <EastIcon className={styles.icon} /> <h5>Products</h5>
                  </div>
                  <div className={styles.infoDiv}>
                    <h5>Kitchen IP:</h5>
                    <h5 style={{ color: "#8b8b8b", marginLeft: '5px' }}>198:23:34:532</h5>
                  </div>
                </div>
              </div>

              <div>
                <TextFieldBGGray
                  style={{ backgroundColor: "#eeeeee !important" }}
                  label="search category and menu"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <SearchRoundedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ButtonGray className={styles.btnStyle}>
                  <SyncRoundedIcon style={{ paddingRight: "5px" }} />
                  Reload
                </ButtonGray>
                <ButtonGray className={styles.btnStyle}>
                  <KitchenIcon style={{ paddingRight: "5px" }} />
                  Test KOT
                </ButtonGray>
              </div>
            </Grid>
            <Grid item xs={12} marginTop={2} className={styles.itemContainer}>
              <Grid container spacing={2}>
                {items.map((item) => (
                  <ItemView
                    item={item}
                    bgColor="#f5f5fb"
                    subContent={
                      <>
                        <img
                          src={RupieeIcon}
                          className={styles.rupeeIcon}
                          alt="rupiee"
                        />
                        <span style={{ paddingTop: "1px" }}>{`${
                          item?.price
                        }/kg, MRP: ${
                          item?.mrp !== 0 ? item?.mrp : item?.price
                        }`}</span>
                      </>
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProductsManage />
        </Grid>
      </Grid>
    </>
  );
}

export default Products;
