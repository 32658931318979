import React, { createContext, useCallback, useEffect, useState } from "react";
import ShopService from "../services/ShopService";
import SubscriptionEndDialog from "../components/popups/SubscriptionEndDialog";
import validityExpired from "../components/utils/validityExpired";
import { useNavigate } from "react-router-dom";
import { ROUTES, STORAGE_KEYS, TAX_PREFERENCES } from "../const";

const ShopContext = createContext();

export function ShopProvider(props) {
  const navigate = useNavigate();

  const [shopId, setShopId] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [openSubscriptionEndDialog, setOpenSubscriptionEndDialog] =
    useState(false);

  const getShopProfile = async () => {
    const resp = await ShopService.loadShopProfile();
    if (!resp) return;
    setSelectedShop(resp);
    setShopId(resp?.id);
  };

  const isSubscriptionExpired = useCallback(async () => {
    if (!selectedShop) return;
    const isExpired = await validityExpired(
      selectedShop?.subscription_end_date
    );
    setOpenSubscriptionEndDialog(isExpired);
    if (isExpired) navigate(ROUTES.SELECT_SHOP);
  }, [selectedShop, navigate]);

  useEffect(() => {
    isSubscriptionExpired();
  }, [shopId, isSubscriptionExpired]);

  useEffect(() => {
    localStorage.setItem(
      STORAGE_KEYS.TAX_PREFERENCE,
      selectedShop?.tax_preference ?? TAX_PREFERENCES.NO_TAX
    );
    localStorage.setItem(STORAGE_KEYS.TAX_REGION, selectedShop?.tax_region);
    //eslint-disable-next-line
  }, [shopId]);

  useEffect(() => {
    getShopProfile();
  }, []);

  return (
    <ShopContext.Provider
      value={{
        selectedShop,
        shopId,
        setSelectedShop,
        setShopId,
        openSubscriptionEndDialog,
        setOpenSubscriptionEndDialog,
      }}
    >
      {props.children}
      <ShopManagement
        openSubscriptionEndDialog={openSubscriptionEndDialog}
        setOpenSubscriptionEndDialog={setOpenSubscriptionEndDialog}
      />
    </ShopContext.Provider>
  );
}

function ShopManagement({
  openSubscriptionEndDialog,
  setOpenSubscriptionEndDialog,
}) {
  return (
    <>
      {openSubscriptionEndDialog && (
        <SubscriptionEndDialog
          handleClose={() => setOpenSubscriptionEndDialog(false)}
        />
      )}
    </>
  );
}

export default ShopContext;
