import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ButtonLightBlue = styled(Button)({
  backgroundColor: "#0160c9",
  borderRadius: "30px",
  textTransform: "capitalize",
  fontSize: "13px",
  padding: "12px 0",
  "&:hover": {
    backgroundColor: "rgba(1, 96, 201, 0.8)",
  },
});

export default ButtonLightBlue;
