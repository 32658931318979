import React from "react";
import { Outlet } from "react-router-dom";
import styles from "./styles.module.css";

function RootLayout() {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    </div>
  );
}

export default RootLayout;
