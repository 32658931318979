import { ROUTES } from "../../../const";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';

const ManageNavConfig = [
  {
    id: "products",
    name: "Products",
    path: ROUTES.CATEGORY,
    icon: ClearAllIcon,
  },
  {
    id: "label-scale",
    name: "Label scale",
    path: ROUTES.LABEL_SCALE,
    icon: PointOfSaleIcon,
  },
  {
    id: "kot",
    name: "KOT",
    path: ROUTES.KOT,
    icon: TextSnippetIcon,
  },
  {
    id: "printers",
    name: "Printers",
    path: ROUTES.PRINTERS,
    icon: LocalPrintshopRoundedIcon,
  },
  {
    id: "salesperson",
    name: "Salesperson",
    path: ROUTES.SALESPERSON,
    icon: GroupIcon,
  },
  {
    id: "settings",
    name: "Settings",
    icon: SettingsIcon,
    submenu: [
      {
        id: "bill",
        name: "Bill & Currency",
        path: ROUTES.BILL_CURRENCY,
      },
      {
        id: "features",
        name: "Features",
        path: ROUTES.FEATURES,
      },
      {
        id: "tax",
        name: "Tax",
        path: ROUTES.TAX,
      },
      {
        id: "info",
        name: "Basic Info",
        path: ROUTES.BASIC_INFO,
      },
    ],
  },
];

export default ManageNavConfig;
