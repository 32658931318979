import React, { useState } from "react";
import { Grid } from "@mui/material";
import Loader from "../../utils/Loading";
import styles from "./styles.module.css";
import HelpIcon from "@mui/icons-material/Help";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { ROUTES} from '../../../const'

function Lock() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate()

  const handleBtnClick = (char) => {
    setPassword((prevPassword) => prevPassword + char);
  };

  const handleBtnLogin = () => {
    setIsLoading(true)
    try {
      console.log(password);
      navigate(ROUTES.SALES)
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false)
  };

  const isPasswordEntered = (position) => {
    return password.length >= position;
  };

  return (
    <>
      <Loader isOpen={isLoading} />
      <Grid container className={styles.textArea}>
        <Grid item xs={12} md={6} className={styles.container}>
          <Grid container rowSpacing={1} style={{ maxWidth: "250px" }}>
            <Grid item xs={12} display="flex" justifyContent={"start"}>
              <span className={styles.head1}>SANOFT</span>
              {"  "}
              <span className={styles.head2}>POS</span>
            </Grid>
            <Grid item xs={12} className={styles.text}>
              <span>
                Kindly proceed by logging into your account to seamlessly
                continue with the billing process.
              </span>
            </Grid>
            <span className={styles.support}>
              <HelpIcon className={styles.icon} />
              Need support
            </span>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={styles.container}>
          <Grid container rowSpacing={3} style={{ maxWidth: "250px" }}>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <Grid container rowSpacing={2}>
                <Grid item xs={3} className={styles.btnConteiner}>
                  <Grid className={styles.pwdButton}>
                    <Grid
                      className={`${isPasswordEntered(1) && styles.pwdActive}`}
                    ></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} className={styles.btnConteiner}>
                  <Grid className={styles.pwdButton}>
                    <Grid
                      className={`${isPasswordEntered(2) && styles.pwdActive}`}
                    ></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} className={styles.btnConteiner}>
                  <Grid className={styles.pwdButton}>
                    <Grid
                      className={`${isPasswordEntered(3) && styles.pwdActive}`}
                    ></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} className={styles.btnConteiner}>
                  <Grid className={styles.pwdButton}>
                    <Grid
                      className={`${isPasswordEntered(4) && styles.pwdActive}`}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <Grid container rowSpacing={1.5}>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("1")}
                  >
                    1
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("2")}
                  >
                    2
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("3")}
                  >
                    3
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("4")}
                  >
                    4
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("5")}
                  >
                    5
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("6")}
                  >
                    6
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("7")}
                  >
                    7
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("8")}
                  >
                    8
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("9")}
                  >
                    9
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => setPassword("")}
                    style={{ fontSize: "12px" }}
                  >
                    Clear
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    onClick={() => handleBtnClick("0")}
                  >
                    0
                  </Grid>
                </Grid>
                <Grid item xs={4} className={styles.btnConteiner}>
                  <Grid
                    className={styles.btn}
                    style={{ fontSize: "12px", backgroundColor: "#0160c9" }}
                    onClick={() => handleBtnLogin()}
                  >
                    Login
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={styles.settings}>
            <div className={styles.subText}>
              <DescriptionIcon className={styles.icon} />
              <span>Report</span>
            </div>
            <div className={styles.subText}>
              <SettingsIcon className={styles.icon} />
              <span>Settings</span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Lock;
