import { STORAGE_KEYS } from '../../const';

function RoundOff(value) {
  let precision = 2;
  const taxRegion = localStorage.getItem(STORAGE_KEYS.TAX_REGION)
  if (taxRegion && taxRegion === 'oman') {
    precision = 3;
  }

  const roundedValue = parseFloat(value).toFixed(precision);
  return Number(roundedValue)
}

export default RoundOff;
