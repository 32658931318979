import React, { useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import styles from "./styles.module.css";
import logo from "./../../../images/Logo.png";
import { Grid } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../const";
import ManageNavConfig from "./NaveBarConfit";
import LogoutIcon from "@mui/icons-material/Logout";
import ButtonGray from "../CustomFields/ButtonGray";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AuthService from "../../../services/AuthService";

function ManageNavebar() {
  const { pathname } = useLocation();
  const [subItem, setSubItem] = useState(null);
  const match = (path) => (path ? matchPath(`${path}/*`, pathname) : false);

  const logoutBtnPressed = async (e) => {
    console.log('logout');
    await AuthService.logout();
    window.location.reload();
  };

  return (
    <Grid container className={styles.CategoryWrapper}>
      <Grid item xs={12} className={styles.logoWrapper}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            className={styles.logoContainer}
          >
            <img src={logo} alt="LOGO" />
          </Grid>
          <Link to={ROUTES.SALES}>
            <Grid item xs={12} display={"flex"} className={styles.subText}>
              <ArrowBackRoundedIcon className={styles.icon} />
              <span>Manage</span>
            </Grid>
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.navList}>
        <Grid container>
          {ManageNavConfig.map((nav) => {
            return (
              <>
                <Link
                  className={`${styles.navLinkWrapper} ${
                    match(nav.path) ? styles.activeNavLink : null
                  } 
                  `}
                  to={nav.submenu ? pathname : nav.path}
                  onClick={() => {
                    if (nav.submenu) {
                      setSubItem(nav.id);
                    } else {
                      setSubItem(null);
                    }
                  }}
                >
                  <div className={styles.navbarContainer}>
                    <nav.icon
                      className={`${styles.icon} ${
                        match(nav.path) ? styles.activeNavLinkIcon : null
                      }
                       `}
                    />
                    <h5 className={styles.navLinkText}>{nav.name}</h5>
                  </div>
                  {nav.submenu && (
                    <ExpandMoreIcon
                      className={`${styles.subItemIcon} ${
                        nav.id === subItem && styles.subItemIconOpen
                      }`}
                    />
                  )}
                </Link>
                {nav.submenu &&
                  subItem === nav.id &&
                  nav.submenu.map((subMenu) => {
                    return (
                      <Link
                        className={`${styles.navLinkWrapper} ${
                          styles.navSubMenuItem
                        } ${match(subMenu.path) ? styles.activeNavLink : null}`}
                        key={subMenu.id}
                        to={subMenu.path}
                      >
                        <h5 className={styles.navLinkText}>{subMenu.name}</h5>
                      </Link>
                    );
                  })}
              </>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.logoutSeccion}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle} onClick={logoutBtnPressed}>
          <LogoutIcon style={{ paddingRight: "5px" }} /> Logout
        </ButtonGray>
      </Grid>
    </Grid>
  );
}

export default ManageNavebar;
