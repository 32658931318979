import React, { useContext, useState } from 'react';
import styles from './styles.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Button } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ProductContext from '../../context/ProductContext';
import CartContext from '../../context/CartContext';
import RoundOff from '../utils/RoundOff';

function CartItemEditDialog({ handleClose, selectedCartItem }) {
  const { items } = useContext(ProductContext);
  const {
    activeSale,
    handleRemoveItemFromSale,
    handleClearItemQty,
    handleClearItemDiscount,
    handleClearItemPrice,
    handleSetItemPrice,
    handleAddItemToSale,
    handleSetItemDiscount,
  } = useContext(CartContext);
  const [activeInput, setActiveInput] = useState('qty');
  const [value, setValue] = useState('');

  const item = items.find((item) => item.id === selectedCartItem.id);
  const cartItem = activeSale.items.find((item) => item.id === selectedCartItem.id);

  const handleNumberClick = (num) => {
    if (!activeInput) return;
    if (num === '.' && value.includes('.')) {
      return;
    }
    const val = value + num;
    setValue(val);
    handleChangeItemValue(Number(val));
  };

  const handleChangeItemValue = (val) => {    
    if (activeInput === 'qty') {
      handleAddItemToSale(cartItem, val);
    } else if (activeInput === 'discount') {
      handleSetItemDiscount(cartItem, val);
    } else if (activeInput === 'price') {
      handleSetItemPrice(cartItem, val);
    }
  };

  const handleInputChange = (inputType) => {
    setActiveInput(inputType);
    setValue('');
  };

  const handleClearValue = () => {
    setValue('');
    if (!activeInput) return;
    if (activeInput === 'qty') {
      if (cartItem.qty === 0) {
        handleRemoveItemFromSale(cartItem);
        handleClose();
      } else {
        handleClearItemQty(item);
      }
    } else if (activeInput === 'discount') {
      handleClearItemDiscount(cartItem);
    } else if (activeInput === 'price') {
      handleClearItemPrice(cartItem);
    }
  };

  const getBtnStyle = (btnAction) => {
    if (btnAction === activeInput) return `${styles.btn} ${styles.selectBtn}`;
    return styles.btn;
  };

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headInfoSec}>
          <Grid container>
            <Grid item xs={10} style={{ paddingTop: '15px' }}>
              <h3 style={{ color: '#222222' }} className={styles.priceTxt}>
                {item?.name}
                <div className={styles.amountBlue}>
                  <CurrencyRupeeIcon className={styles.rupieeIcon} />
                  <h5>{cartItem?.rate}</h5>
                </div>
              </h3>
              <h4 style={{ color: '#8b8b8b' }} className={styles.priceTxt}>
                Purchase QTY:
                <div className={styles.amountGray}>
                  <h4>
                    {cartItem?.qty} {item?.unit}
                  </h4>
                  <span style={{ paddingLeft: '20px' }}>{`${cartItem?.qty} X ${cartItem?.rate} = ${RoundOff(
                    cartItem?.qty * cartItem?.rate
                  )}`}</span>
                </div>
              </h4>
              <h4 style={{ color: '#8b8b8b' }} className={styles.priceTxt}>
                Tax:
                <div className={styles.amountGray} style={{ paddingRight: 10 }}>
                  <h4>{cartItem?.tax || 0}</h4>
                </div>
                Cess:
                <div className={styles.amountGray}>
                  <h4>{cartItem?.cess || 0}</h4>
                </div>
              </h4>
              <h4 style={{ color: '#8b8b8b' }} className={styles.priceTxt}>
                Discount:
                <div className={styles.amountGray}>
                  <h4>{cartItem?.discountAmount || 0}</h4>
                </div>
              </h4>
              <h3 style={{ color: '#222222' }} className={styles.priceTxt}>
                Total payable:
                <div className={styles.amountBlue}>
                  <CurrencyRupeeIcon style={{ fontSize: '16px' }} />
                  <h4>{cartItem?.payable}</h4>
                </div>
              </h3>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <CloseRoundedIcon onClick={handleClose} className={styles.icon} />
            </Grid>
          </Grid>
        </div>
        <div className={styles.inputSec}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('1')}>
                <h3>1</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('2')}>
                <h3>2</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('3')}>
                <h3>3</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button className={getBtnStyle('qty')} onClick={() => handleInputChange('qty')}>
                <h5>QTY</h5>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('4')}>
                <h3>4</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('5')}>
                <h3>5</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('6')}>
                <h3>6</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button className={getBtnStyle('discount')} onClick={() => handleInputChange('discount')}>
                <h5>DISC</h5>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('7')}>
                <h3>7</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('8')}>
                <h3>8</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('9')}>
                <h3>9</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button className={getBtnStyle('price')} onClick={() => handleInputChange('price')}>
                <h5>PRICE</h5>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick()}>
                <h5>+/-</h5>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('0')}>
                <h3>0</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" className={styles.btn} onClick={() => handleNumberClick('.')}>
                <h3>.</h3>
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button className={styles.btn} onClick={handleClearValue}>
                <h5>DEL</h5>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default CartItemEditDialog;
