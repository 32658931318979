import React from "react";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const EditIcon = styled(ModeEditOutlineRoundedIcon)({
  color: "#8b8b8b",
  fontSize: "18px",
});

export default function CustomTimePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        slots={{
          openPickerIcon: EditIcon,
        }}
        sx={{
          "& fieldset": { border: "none" },
          "& .MuiInputBase-input": { padding: "6px 0 0 !important" },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
