import React, { useState } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import ButtonBlue from "../utils/CustomFields/ButtonBlue";
import TextFieldBGGray from "../utils/CustomFields/TextFieldBGGray";

function AddCustomer({ closeDialog, addCustomerHandler }) {
  const [data, setData] = useState({});

  const handleCreateCustomer = () => {
    if (!data.name || !data.mobile) {
      return;
    }
    addCustomerHandler(data);
  };

  return (
    <div className={styles.popUp}>
      <Grid
        container
        className={styles.contentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Grid item xs={12} mb={2}>
          <h3>Add Customer</h3>
        </Grid>
        <Grid item xs={12} className={styles.contentArea} mb={2}>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Name</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter name"
                size="small"
                fullWidth
                value={data?.name}
                onChange={(event) =>
                  setData({ ...data, name: event.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Mobile</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter mobile"
                size="small"
                fullWidth
                value={data?.mobile}
                onChange={(event) =>
                  setData({ ...data, mobile: event.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Location</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter location"
                size="small"
                fullWidth
                value={data?.location}
                onChange={(event) =>
                  setData({ ...data, location: event.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Credit limit</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter credit limit"
                size="small"
                fullWidth
                value={data?.credit_limit}
                onChange={(event) =>
                  setData({ ...data, credit_limit: event.target.value })
                }
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>GSTIN</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter GSTIN"
                size="small"
                fullWidth
                value={data?.trn_number}
                onChange={(event) =>
                  setData({ ...data, trn_number: event.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonBlue
            style={{ padding: "7px 12px" }}
            className={styles.buttonGray}
            onClick={closeDialog}
          >
            Cancel
          </ButtonBlue>
          <ButtonBlue
            style={{ padding: "7px 12px" }}
            onClick={handleCreateCustomer}
          >
            Submit
          </ButtonBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddCustomer;
