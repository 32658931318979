import React from "react";
import { Outlet } from "react-router-dom";
import styles from './styles.module.css'
import { Grid } from "@mui/material";
import ManageNavebar from "../../utils/ManageNavbar/ManageNavebar";

function ManageLayout() {
  return (
    <div className={styles.ContentsWrapper}>
      <Grid container columnGap={2} marginLeft={0} className={styles.container}>
        <Grid item className={styles.navbar}>
          <ManageNavebar />
        </Grid>
        <Grid item className={styles.contentArea}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
  );
}

export default ManageLayout;
