import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  InputAdornment,
  IconButton,
  Paper,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { ROUTES } from "../../../const";
import CustomerService from "../../../services/CustomerService";
import TextFieldBGWhite from "../../utils/CustomFields/TextFieldBGWhite";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";
import DataTable from "../../utils/DataTable";
import AddCustomer from "../../popups/AddCustomer";
import EditCustomer from "../../popups/EditCustomer";
import Loader from "../../utils/Loading";
import Info from "../../utils/Alert/Info";
import validityExpired from "../../utils/validityExpired";
import RestrictionInfo from "../../utils/Alert/RestrictionInfo";
import ShopContext from "../../../context/ShopContext";

function Customers() {
  const { selectedShop } = useContext(ShopContext);

  const [customers, setCustomers] = useState([]);
  const [editCustomer, setEditCustomer] = useState(null);
  const [addCustomer, setAddCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [count, setCount] = useState(0);
  const [filterText, setFilterText] = useState("");

  const navigate = useNavigate();

  const moveToHomePage = () => {
    navigate(ROUTES.SALES);
  };

  const getCustomers = async ({ _offset = offset, _limit = limit } = {}) => {
    setIsLoading(true);
    try {
      const res = await CustomerService.getCustomersWithCount({
        offset: _offset,
        limit: _limit,
        search: filterText,
      });
      setCustomers(res?.data);
      setCount(res?.count);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const editCustomerHandler = (cust) => {
    setEditCustomer(cust);
  };

  const headerData = [
    {
      label: "Name",
      id: "name",
      type: "text",
    },
    {
      label: "Mobile",
      id: "mobile",
      type: "text",
    },
    {
      label: "Credit Limit",
      id: "credit_limit",
      type: "floatAmount",
    },
    {
      label: "Balance",
      id: "balance",
      type: "floatAmount",
    },
    {
      label: "Edit",
      id: "edit",
      type: "button",
      title: "Edit",
      clickHandler: editCustomerHandler,
    },
  ];

  const handleCustomerDetails = (cust) => {
    navigate(ROUTES.CUSTOMER_DETAILS.replace(":customerId", cust.id));
  };

  const handleAddCustomerDialog = () => {
    setAddCustomer(true);
  };

  const handleAddCusotmer = async (customer) => {
    setIsLoading(true);
    try {
      await CustomerService.createCustomer(customer);
      setAddCustomer(false);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  const hadleEditCustomer = async (customer) => {
    setIsLoading(true);
    try {
      await CustomerService.updateCustomer(customer?.id, customer);
      setEditCustomer(null);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    let offset = newPage * limit;
    setOffset(offset);
    getCustomers({ _offset: offset });
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getCustomers({ _limit: limit });
  };

  useEffect(() => {
    if (!selectedShop?.id || validityExpired(selectedShop?.customer_support_validity)) return;
    getCustomers();
    //eslint-disable-next-line
  }, [selectedShop?.id]);

  return (
    <>
      <Grid container className={styles.container} rowGap={2}>
        <Loader isOpen={isLoading} />
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid display="flex" alignItems="center">
            <ArrowBackRoundedIcon
              className={styles.largeIcon}
              onClick={moveToHomePage}
            />
            <Grid className={styles.titleWrapper}>
              <h2>Customers</h2>
              <Grid display="flex" alignItems="center">
                <h5 style={{ color: "#8b8b8b" }}>Home</h5>
                <ArrowForwardRoundedIcon className={styles.smallIcon} />
                <h5>Customers</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <CloseRoundedIcon
              className={styles.largeIcon}
              onClick={moveToHomePage}
            />
          </Grid>
        </Grid>
        {selectedShop && validityExpired(selectedShop?.customer_support_validity) ? (
          <RestrictionInfo
            title={"Customer support feature not available "}
            content={"To get customer support options, contact support team.."}
          />
        ) : (
          <>
            <Grid item xs={12} className={styles.contentWrapper}>
              <TextFieldBGWhite
                label="search customer"
                size="small"
                value={filterText}
                onChange={(event) => setFilterText(event.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchRoundedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <ButtonBlue
                style={{ padding: 6 }}
                onClick={handleAddCustomerDialog}
              >
                Add Customer
              </ButtonBlue>
            </Grid>
            <Grid item xs={12} className={styles.tableContainer}>
              {customers && customers.length ? (
                <Paper>
                  <DataTable
                    columns={headerData}
                    rows={customers}
                    rowClickListener={(row) => handleCustomerDetails(row)}
                  />
                  <TablePagination
                    rowsPerPageOptions={[20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
              ) : (
                !isLoading && (
                  <Info
                    title="No customers to list"
                    content="You have no customers to list with current filter configuration. Please clear the filters or create a customer"
                  />
                )
              )}
            </Grid>
          </>
        )}
      </Grid>
      {addCustomer && (
        <AddCustomer
          closeDialog={() => setAddCustomer(false)}
          addCustomerHandler={handleAddCusotmer}
        />
      )}
      {editCustomer && (
        <EditCustomer
          closeDialog={() => setEditCustomer(null)}
          editCustomerHandler={hadleEditCustomer}
          customer={editCustomer}
        />
      )}
    </>
  );
}

export default Customers;
