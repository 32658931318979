import { Alert, AlertTitle } from '@mui/material';
import React from 'react';
import { CONTACT_NUMBER } from '../../../const';
export default function RestrictionInfo(props) {
  const { title, content } = props;
  return (
    <Alert severity="warning" style={{ margin: '10px 0' }}>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {content} -{' '}
      <strong>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://api.whatsapp.com/send?phone=${CONTACT_NUMBER}&text=Hey%20$SANOFT,%20Upgrade%20Subscription`}
        >
          Contact Us
        </a>
      </strong>
    </Alert>
  );
}
