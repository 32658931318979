import BackendService from "./BackendService";
import { STORAGE_KEYS } from "../const";

async function getUserProfile(
  shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID)
) {
  if (!shopId) return;

  return BackendService.getData("/user_profile/");
}

const getUserShops = async () => {
  const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID)
  if (shopId) return;
  return BackendService.getData("/shops/")
}

const UserService = {
  getUserShops,
  getUserProfile,
};

export default UserService;
