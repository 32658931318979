import React from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import CustomFormSwitch from "../../utils/CustomFields/CustomFormSwitch";
import CustomCheckbox from "../../utils/CustomFields/CustomCheckbox";

function Features() {
  return (
    <Grid container className={styles.contentWrapper} mt={0} rowGap={2}>
      <Grid item xs={12}>
        <h3>Features</h3>
      </Grid>
      <Grid item xs={12} className={styles.dataWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <h4>Show token number</h4>
                <h5>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </h5>
              </Grid>
              <Grid>
                <CustomFormSwitch />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <h4>Enable payment page</h4>
                <h5>Lorem Ipsum is printing and typesetting industry.</h5>
              </Grid>
              <Grid>
                <CustomFormSwitch />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <h4>Skip payment page on bill</h4>
                <h5>
                  Lorem Ipsum is dummy text of the printing and typesetting
                  industry.
                </h5>
              </Grid>
              <Grid>
                <CustomFormSwitch />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <h4>Order with full payment only</h4>
                <h5>Lorem Ipsum is dummy .</h5>
              </Grid>
              <Grid>
                <CustomFormSwitch />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <h4>Enable salesperson login</h4>
                <h5>
                  Lorem Ipsum is dummy text of the printing and typesetting
                  industry.
                </h5>
              </Grid>
              <Grid>
                <CustomFormSwitch />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.checkbox}>
              <CustomCheckbox checked={true} />
              <h4>Enable advanced features</h4>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Features;
