import React from "react";
import { Grid } from "@mui/material";
import styles from "./styles.module.css";

function ItemView({ item, bgColor, subContent, clickItemHandler }) {
  return (
    <>
      <Grid
        item
        className={styles.itemContainer}
        onClick={() => clickItemHandler(item)}
      >
        <Grid
          container
          className={styles.itemArea}
        >
          <Grid
            item
            xs={12}
            className={styles.item}
            style={{ backgroundColor: `${bgColor ?? "white"}` }}
          >
            <img src={item?.image} alt="img" className={styles.itemImage} />
            <Grid item xs={12}>
              <h5>{item?.name.toLowerCase()}</h5>
            </Grid>
            <Grid item xs={12} display={"flex"} alignContent={"center"}>
              {subContent}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ItemView;
