import React from "react";
import styles from "./styles.module.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Grid, Typography } from "@mui/material";
import ButtonBlue from "../utils/CustomFields/ButtonBlue";

function TerminalForceAttachDialog({ handleClose, forceAttachTerminal }) {
  return (
    <div className={styles.popUp}>
      <Grid
        container
        className={styles.billContentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
        rowSpacing={1.5}
      >
        <Grid item xs={12} mb={2} display="flex" justifyContent="space-between">
          <h3>Force Attach Terminal</h3>
          <CloseRoundedIcon onClick={handleClose} className={styles.icon} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            The terminal is already attached! Forcing attachment will disconnect
            the current terminal. Are you sure you want to continue?
          </Typography>
        </Grid>
        <Grid item xs={12} className={styles.footSec}>
          <ButtonBlue className={styles.footerBtn} onClick={handleClose}>Close</ButtonBlue>
          <ButtonBlue className={styles.footerBtn} onClick={forceAttachTerminal}>Attach</ButtonBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default TerminalForceAttachDialog;
