async function getMachines() {
  // return localStorage.getItem('label-scale')
  return [
    // {
    //   name: "machine 1",
    //   mac_id: "02adf235f332",
    //   machine_no: 2232,
    //   bill_prifix: "AV2000",
    //   support_sale: true,
    //   support_unicode: false,
    //   active: true,
    // },
    // {
    //   name: "machine 2",
    //   mac_id: "02adf235f333",
    //   machine_no: 2232,
    //   bill_prifix: "AV3000",
    //   support_sale: true,
    //   support_unicode: false,
    //   active: false,
    // },
  ];
}
async function updateMachine(id, data = {}) {
  return localStorage.setItem();
}
async function addMachine(data = {}) {
  return localStorage.setItem("label-scale", data);
}
async function deactivateMachine(id, data = {}) {
  return localStorage.setItem("label-scale", data);
}

const machineService = {
  getMachines,
  updateMachine,
  addMachine,
  deactivateMachine,
};

export default machineService;
