import React, { useContext } from "react";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import ProductsManage from "../../utils/ProductsManage/ProductsManage";
import TextFieldBGGray from "../../utils/CustomFields/TextFieldBGGray";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ButtonGray from "../../utils/CustomFields/ButtonGray";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import Loader from "../../utils/Loading";
import ItemView from "../../utils/ItemView/ItemView";
import { ROUTES } from "../../../const";
import ProductContext from "../../../context/ProductContext";

function Categories() {
  const { categories, isLoading: loadingProductContext } =
    useContext(ProductContext);
  const navigate = useNavigate();

  const onClickItemHandler = (item) => {
    const path = ROUTES.PRODUCTS.replace(":categoryId", item?.id);
    navigate(path);
  };

  return (
    <>
      <Grid container style={{ height: "100%", marginTop: 0 }} rowSpacing={2}>
        <Loader isOpen={loadingProductContext} />
        <Grid item xs={12} className={styles.contentWrapper}>
          <Grid container height="100%">
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <h2>Categories</h2>
              <div>
                <TextFieldBGGray
                  style={{ backgroundColor: "#eeeeee !important" }}
                  label="search category and menu"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <SearchRoundedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ButtonGray className={styles.btnStyle}>
                  <SyncRoundedIcon style={{ paddingRight: "5px" }} />
                  Reload
                </ButtonGray>
              </div>
            </Grid>
            <Grid item xs={12} marginTop={2} className={styles.itemContainer}>
              <Grid container spacing={2}>
                {categories.map((category) => (
                  <ItemView
                    item={category}
                    bgColor="#f5f5fb"
                    subContent={
                      <span style={{ paddingTop: "1px" }}>{`${
                        category.count ?? 0
                      } Products`}</span>
                    }
                    onClickItem={onClickItemHandler}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ProductsManage />
        </Grid>
      </Grid>
    </>
  );
}

export default Categories;
