import React, { useContext } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import logo from "./../../../images/Logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import ButtonGray from "../CustomFields/ButtonGray";
import ProductContext from "../../../context/ProductContext";
import AuthService from "../../../services/AuthService";

function CategoriesNavebar({ categories }) {
  const { selectedCategory, setSelectedCategory } = useContext(ProductContext);

  const logoutBtnPressed = async (e) => {
    await AuthService.logout();
    window.location.reload();
  };

  return (
    <Grid container className={styles.CategoryWrapper}>
      <Grid item xs={12} className={styles.logoWrapper}>
        <Grid container>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            className={styles.logoContainer}
          >
            <img src={logo} alt="LOGO" />
          </Grid>
          <Grid item xs={12} className={styles.subText}>
            <span>{`${categories.length} Categories`}</span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.navList}>
        <Grid container>
          {categories.map((category) => {
            return (
              <Grid
                item
                xs={12}
                key={category?.id}
                className={`${styles.navItems} ${
                  selectedCategory?.name === category?.name &&
                  styles.selectedCategory
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                <span>{category?.name.toLowerCase()}</span>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.logoutSeccion} >
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle} onClick={logoutBtnPressed}>
          <LogoutIcon style={{ paddingRight: "5px" }} /> Logout
        </ButtonGray>
      </Grid>
    </Grid>
  );
}

export default CategoriesNavebar;
