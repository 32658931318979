import { createContext, useState, useEffect } from "react";
import ItemService from "../services/ItemService";

const ProductContext = createContext();

export function ProductProvider(props) {
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getCategories = async () => {
    return ItemService.getItemCategories();
  };

  const getItems = async () => {
    return ItemService.getItems();
  };

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      const [categories, items] = await Promise.all([
        getCategories(),
        getItems(),
      ]);
      categories.forEach((category) => {
        category.count = (items || []).filter(
          (item) => item?.category === category.id
        ).length;
      });
      
      setCategories(categories);
      setSelectedCategory(categories[0]);
      setItems(items);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // if (items.length !== 0) return;
    getInitialData();
    //eslint-disable-next-line
  }, []);

  return (
    <ProductContext.Provider
      value={{
        selectedCategory,
        setSelectedCategory,
        categories,
        items,
        isLoading,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
}

export default ProductContext;
