import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./const";
import LoginPage from "./components/pages/Login/Login";
import ErrorPage from "./components/pages/ErrorPage/ErrorPage";
import SalesPage from "./components/pages/Sales/Sales";
import PrivateRoute from "./components/utils/PrivateRoute";
import BillNumberPage from "./components/pages/BiIlNumber/BillNumber";
import RootLayout from "./components/layout/RootLayout/RootLayout";
import PosLayout from "./components/layout/PosLayout/PosLayout";
import ShiftPage from "./components/pages/Shift/Shift";
import { UserProvider } from "./context/UserContext";
import { ShopProvider } from "./context/ShopContext";
import { CartProvider } from "./context/CartContext";
import { ProductProvider } from "./context/ProductContext";
import ShopPage from "./components/pages/Shop/Shop";
import CounterPage from "./components/pages/Counter/Counter";
import ManageLayout from "./components/layout/ManageLayout/ManageLayout";
import CategoriesPage from "./components/pages/Products/Categories";
import LabelScalePage from "./components/pages/LabelScale/LabelScale";
import KOTPage from "./components/pages/KOT/KOT";
import SalesPersonPage from "./components/pages/SalesPerson/SalesPerson";
import BillSettingsPage from "./components/pages/Settings/BillSettings";
import FeaturesSettingsPage from "./components/pages/Settings/Features";
import TaxSettingsPage from "./components/pages/Settings/Tax";
import BasicInfoSettingsPage from "./components/pages/Settings/BasicInfo";
import PrintersPage from "./components/pages/Printers/Printers";
import ProductsPage from "./components/pages/Products/Products";
import ActiveOrdersPage from "./components/pages/ActiveOrders/ActiveOrders";
import SalesReportPage from "./components/pages/SalesReport/SalesReport";
import PayPage from "./components/pages/Paypage/Paypage";
import LockScreenPage from "./components/pages/Lock/Lock";
import CustomersPage from "./components/pages/Customers/Customers";
import CustomerDetailsPage from "./components/pages/Customers/CustomerDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <LoginPage /> },
      { path: `${ROUTES.LOGIN}`, element: <LoginPage /> },
    ],
  },
  {
    path: "/",
    element: (
      <ShopProvider>
        <UserProvider>
          <RootLayout />
        </UserProvider>
      </ShopProvider>
    ),
    children: [
      {
        path: `${ROUTES.SELECT_SHOP}`,
        element: <PrivateRoute element={ShopPage} />,
      },
      {
        path: `${ROUTES.COUNTER}`,
        element: <PrivateRoute element={CounterPage} />,
      },
      {
        path: `${ROUTES.BILL_NUMBER}`,
        element: <PrivateRoute element={BillNumberPage} />,
      },
      {
        path: `${ROUTES.SHIFT}`,
        element: <PrivateRoute element={ShiftPage} />,
      },
      {
        path: `${ROUTES.LOCK_SCREEN}`,
        element: <PrivateRoute element={LockScreenPage} />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ShopProvider>
        <UserProvider>
          <ProductProvider>
            <CartProvider>
              <PosLayout />
            </CartProvider>
          </ProductProvider>
        </UserProvider>
      </ShopProvider>
    ),
    children: [
      {
        path: `${ROUTES.SALES}`,
        element: <PrivateRoute element={SalesPage} />,
      },
      {
        path: `${ROUTES.ACTIVE_ORDERS}`,
        element: <PrivateRoute element={ActiveOrdersPage} />,
      },
      {
        path: `${ROUTES.CUSTOMERS}`,
        element: <PrivateRoute element={CustomersPage} />,
      },
      {
        path: `${ROUTES.CUSTOMER_DETAILS}`,
        element: <PrivateRoute element={CustomerDetailsPage} />,
      },
      {
        path: `${ROUTES.SALES_REPORT}`,
        element: <PrivateRoute element={SalesReportPage} />,
      },
      {
        path: `${ROUTES.PAYPAGE}`,
        element: <PrivateRoute element={PayPage} />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ShopProvider>
        <UserProvider>
          <ProductProvider>
            <ManageLayout />
          </ProductProvider>
        </UserProvider>
      </ShopProvider>
    ),
    children: [
      {
        path: `${ROUTES.CATEGORY}`,
        element: <PrivateRoute element={CategoriesPage} />,
      },
      {
        path: `${ROUTES.PRODUCTS}`,
        element: <PrivateRoute element={ProductsPage} />,
      },
      {
        path: `${ROUTES.LABEL_SCALE}`,
        element: <PrivateRoute element={LabelScalePage} />,
      },
      {
        path: `${ROUTES.KOT}`,
        element: <PrivateRoute element={KOTPage} />,
      },
      {
        path: `${ROUTES.PRINTERS}`,
        element: <PrivateRoute element={PrintersPage} />,
      },
      {
        path: `${ROUTES.SALESPERSON}`,
        element: <PrivateRoute element={SalesPersonPage} />,
      },
      {
        path: `${ROUTES.BILL_CURRENCY}`,
        element: <PrivateRoute element={BillSettingsPage} />,
      },
      {
        path: `${ROUTES.FEATURES}`,
        element: <PrivateRoute element={FeaturesSettingsPage} />,
      },
      {
        path: `${ROUTES.TAX}`,
        element: <PrivateRoute element={TaxSettingsPage} />,
      },
      {
        path: `${ROUTES.BASIC_INFO}`,
        element: <PrivateRoute element={BasicInfoSettingsPage} />,
      },
    ],
  },
  {
    path: "/",
    element: <RootLayout />,
    children: [{ path: "*", element: <ErrorPage /> }],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
