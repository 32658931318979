import BackendService from "./BackendService";
import { STORAGE_KEYS } from "../const";

async function getHeighestBillNumber(params) {
  return BackendService.getData("/sales/highest-bill-no/", {}, params);
}

async function loadShopProfile(
  shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID)
) {
  if (!shopId) return;

  return BackendService.getData("/shop_profile/");
}
 
async function getPaymentModes(params) {
  return BackendService.getData("/payment-modes/", {}, params);
}

const ShopSettingsService = {
  getHeighestBillNumber,
  loadShopProfile,
  getPaymentModes,
};

export default ShopSettingsService;
