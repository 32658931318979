import React, { useState, useEffect } from 'react';
import Loader from '../../utils/Loading';
import { useNavigate } from 'react-router-dom';
import TextFieldBGTransparent from '../../utils/CustomFields/TextFieldBGTransparent';
import ButtonLightBlue from '../../utils/CustomFields/ButtonLightBlue';
import styles from './styles.module.css';
import { Grid } from '@mui/material';
import { ROUTES, STORAGE_KEYS } from '../../../const';
import ShopService from '../../../services/ShopService';
import DateTimeHelpers from '../../../helpers/DateTimeHelpers';

const getShiftName = () => {
  const date = DateTimeHelpers.convertDateToIsoDMY(new Date());
  return `${date}-Shift-${1}`;
};

function Shift() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [shiftName, setShiftName] = useState(getShiftName());
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentModes, setPaymentModes] = useState(null);
  const [tenders, setTenders] = useState([]);

  const onClickCreateShiftHandler = () => {
    if (!shiftName) {
      setErrorMessage('Shift name should not be empty.');
      return;
    }
    console.log(tenders);
    setIsLoading(true);
    localStorage.setItem(STORAGE_KEYS.SHIFT, shiftName);
    setIsLoading(false);
    if (localStorage.getItem(STORAGE_KEYS.SALESPERSON_LOGIN)) {
      navigate(ROUTES.LOCK_SCREEN);
    } else {
      navigate(ROUTES.SALES);
    }
  };

  const getPaymentModes = async () => {
    setIsLoading(true);
    try {
      const resp = await ShopService.getPaymentModes();
      setPaymentModes(resp);
      const tenders = resp.map((paymentMode) => {
        return { payment_mode: paymentMode, amount: 0 };
      });
      setTenders(tenders);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getPaymentModes();
  }, []);
  return (
    <>
      <Loader isOpen={isLoading} />
      <Grid container rowSpacing={4} className={styles.textArea}>
        <Grid item xs={12} style={{ paddingBottom: 20 }}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} display="flex" justifyContent={'center'}>
              <span className={styles.head1}>
                SHIFT <span className={styles.head2}>SETUP</span>
              </span>
            </Grid>
            <Grid item xs={12} className={styles.text}>
              <span>Please set the shift setup for continue billing.</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TextFieldBGTransparent
                id="shift-name"
                label="Shift name"
                variant="outlined"
                value={shiftName}
                onChange={(e) => setShiftName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} display="flex" justifyContent={'center'}>
            <span className={styles.title}>Opening Balance</span>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {(paymentModes || []).map((paymentMode) => (
              <Grid item xs={12} sm={6}>
                <TextFieldBGTransparent
                  id="shift-name"
                  label={paymentMode.name}
                  variant="outlined"
                  value={tenders.find((tender) => tender.payment_mode.id === paymentMode.id)?.amount || ''}
                  onChange={(e) => {
                    const updatedTenders = tenders.map((tender) =>
                      tender.payment_mode.id === paymentMode.id ? { ...tender, amount: Number(e.target.value) } : tender
                    );
                    setTenders(updatedTenders);
                  }}
                  fullWidth
                />
              </Grid>
            ))}
            <Grid item xs={12} display="flex" justifyContent="center" className={styles.errorMsg}>
              {errorMessage && <span>{errorMessage}</span>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <ButtonLightBlue variant="contained" onClick={onClickCreateShiftHandler} fullWidth>
                Start
              </ButtonLightBlue>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Shift;
