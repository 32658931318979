import BackendService from "./BackendService";

async function getTerminals(params) {
  return BackendService.getData("/terminals/", {}, params);
}

async function attachTerminal(id, data = {}) {
  return BackendService.patch(`/terminals/${id}/attach/`, data);
}

async function detachTerminal(id) {
  return BackendService.patch(`/terminals/${id}/detach/`);
}

const TerminalService = {
  getTerminals,
  attachTerminal,
  detachTerminal,
};

export default TerminalService;
