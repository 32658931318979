// import React, { useState } from "react";
// import styles from "./styles.module.css";
// import { Grid } from "@mui/material";
// import LockIcon from "@mui/icons-material/Lock";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import SyncIcon from "@mui/icons-material/Sync";
// import SettingsIcon from "@mui/icons-material/Settings";
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import ButtonGray from "../CustomFields/ButtonGray";
// import { Link } from "react-router-dom";
// import { ROUTES } from "../../../const";
// import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import AddExpenses from "../../popups/AddExpenses";

// function SalesManage() {
//   const [addExpense, setAddExpense] = useState(false);

//   const handleAddExpense = () => {
//     setAddExpense(true);
//   };

//   return (
//     <>
//       <Grid marginLeft={0} className={styles.contentWrapper}>
//         <Grid className={styles.container}>
//           <Grid>
//             <Link to={ROUTES.LOCK_SCREEN}>
//               <ButtonGray
//                 fullWidth
//                 veriant="container"
//                 className={styles.btnStyle}
//               >
//                 <LockIcon className={styles.icon} /> <span>Lock</span>
//               </ButtonGray>
//             </Link>
//           </Grid>
//           <Grid>
//             <Link to={ROUTES.ACTIVE_ORDERS}>
//               <ButtonGray
//                 fullWidth
//                 veriant="container"
//                 className={styles.btnStyle}
//               >
//                 <ShoppingCartIcon className={styles.icon} /> <span>Orders</span>
//               </ButtonGray>
//             </Link>
//           </Grid>
//           <Grid>
//             <Link to={ROUTES.CATEGORY}>
//               <ButtonGray
//                 fullWidth
//                 veriant="container"
//                 className={styles.btnStyle}
//               >
//                 <SyncIcon className={styles.icon} /> <span>Manage</span>
//               </ButtonGray>
//             </Link>
//           </Grid>
//           <Grid>
//             <Link to={ROUTES.PAYPAGE}>
//               <ButtonGray
//                 fullWidth
//                 veriant="container"
//                 className={styles.btnStyle}
//               >
//                 <SettingsIcon className={styles.icon} /> <span>Sync</span>
//               </ButtonGray>
//             </Link>
//           </Grid>
//           <Grid>
//             <Link to={ROUTES.SALES_REPORT}>
//               <ButtonGray
//                 fullWidth
//                 veriant="container"
//                 className={styles.btnStyle}
//               >
//                 <LibraryBooksIcon className={styles.icon} />{" "}
//                 <span>Sales report</span>
//               </ButtonGray>
//             </Link>
//           </Grid>
//           <Grid>
//             <ButtonGray
//               fullWidth
//               veriant="container"
//               className={styles.btnStyle}
//               onClick={handleAddExpense}
//             >
//               <RequestQuoteIcon className={styles.icon} /> <span>Expense</span>
//             </ButtonGray>
//           </Grid>
//           <Grid>
//             <Link to={ROUTES.CUSTOMERS}>
//               <ButtonGray
//                 fullWidth
//                 veriant="container"
//                 className={styles.btnStyle}
//               >
//                 <PeopleAltIcon className={styles.icon} /> <span>Customers</span>
//               </ButtonGray>
//             </Link>
//           </Grid>
//         </Grid>
//       </Grid>
//       {addExpense && (<AddExpenses closeDialog={() => setAddExpense(false)}/>)}
//     </>
//   );
// }

// export default SalesManage;

import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tab, { tabClasses } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import LockIcon from "@mui/icons-material/Lock";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SyncIcon from "@mui/icons-material/Sync";
import SettingsIcon from "@mui/icons-material/Settings";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import styles from "./styles.module.css";
import { ROUTES } from "../../../const";
import AddExpenses from "../../popups/AddExpenses";
import { useNavigate } from "react-router-dom";

const TabItem = styled(Tab)(({ theme }) => ({
  textTransform: "initial",
  margin: theme.spacing(0, 1),
  minHeight: 0,
  minWidth: "max-content",
  fontWeight: 500,
  fontSize: "16px",
  color: "#8b8b8b",
  backgroundColor: "#f5f5fb",
  position: "relative",
  borderRadius: "8px",
  [`&.${tabClasses.selected}`]: {
    backgroundColor: "#eeeeee",
  },
  [theme.breakpoints.up("md")]: {
    minWidth: 0,
  },
  "&.Mui-selected": {
    color: "#8b8b8b",
  },
  "&:hover": {
    backgroundColor: "#eeeeee",
  },
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

function SalesManage() {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(0);

  const [addExpense, setAddExpense] = useState(false);

  const handleAddExpense = () => {
    setAddExpense(true);
  };

  return (
    <>
      <StyledTabs
        value={tabIndex}
        onChange={(e, index) => setTabIndex(index)}
        className={styles.wrapper}
        variant="scrollable"
        scrollButtons
      >
        <TabItem
          icon={<LockIcon className={styles.icon} />}
          iconPosition="start"
          label={"Lock"}
          onClick={() => navigate(ROUTES.LOCK_SCREEN)}
        />
        <TabItem
          icon={<ShoppingCartIcon className={styles.icon} />}
          iconPosition="start"
          label={"Orders"}
          onClick={() => navigate(ROUTES.ACTIVE_ORDERS)}
        />
        <TabItem
          icon={<SettingsIcon className={styles.icon} />}
          iconPosition="start"
          label={"Manage"}
          onClick={() => navigate(ROUTES.CATEGORY)}
        />
        <TabItem
          icon={<SyncIcon className={styles.icon} />}
          iconPosition="start"
          label={"Sync"}
          onClick={() => navigate(ROUTES.PAYPAGE)}
        />
        <TabItem
          icon={<LibraryBooksIcon className={styles.icon} />}
          iconPosition="start"
          label={"Sales report"}
          onClick={() => navigate(ROUTES.SALES_REPORT)}
        />
        <TabItem
          icon={<RequestQuoteIcon className={styles.icon} />}
          iconPosition="start"
          label={"Expense"}
          onClick={handleAddExpense}
        />
        <TabItem
          icon={<PeopleAltIcon className={styles.icon} />}
          iconPosition="start"
          label={"Customers"}
          onClick={() => navigate(ROUTES.CUSTOMERS)}
        />
      </StyledTabs>
      {addExpense && <AddExpenses closeDialog={() => setAddExpense(false)} />}
    </>
  );
}

export default SalesManage;
