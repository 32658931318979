import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextFieldBGWhite = styled(TextField)({
  "& label.Mui-focused": {
    color: "#8b8b8b",
  },
  "& .MuiInputLabel-root": {
    color: "#8b8b8b",
    fontSize: "12px",
  },
  "& .MuiOutlinedInput-root": {
    border: "none",
    borderRadius: "10px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      color: "#8b8b8b",
      fontSize: "14px",
    },
  },
});

export default TextFieldBGWhite;
