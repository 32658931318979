import React from "react";
import { Alert, AlertTitle } from "@mui/material";
export default function Info(props) {
  const { title, content, severity } = props;
  return (
    <Alert severity={severity || "info"} style={{ marginTop: "15px" }}>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {content}
    </Alert>
  );
}
