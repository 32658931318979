import React, { createContext, useState, useEffect, useContext } from "react";
import UserService from "../services/UserService";
import ShopContext from "./ShopContext";

const UserContext = createContext();

export function UserProvider(props) {
  const { shopId } = useContext(ShopContext);

  const [user, setUser] = useState(null);

  const loadUserProfile = async () => {
    const resUser = await UserService.getUserProfile();
    if (!resUser) return;
    setUser(resUser);
  };

  useEffect(() => {
    loadUserProfile();
  }, [shopId]);

  return (
    <UserContext.Provider value={{ user, setUser, loadUserProfile }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
