import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ButtonGray = styled(Button)({
  backgroundColor: "#f5f5fb",
  color: "#8b8b8b",
  textTransform: "capitalize",
  borderRadius: "10px",
  minWidth: "0px !important",
  "&:hover": {
    backgroundColor: "#eeeeee",
  },
});

export default ButtonGray;
