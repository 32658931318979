import React, { useState } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import TextFieldBGGray from "../../utils/CustomFields/TextFieldBGGray";

function BillSettings() {
  const [bill, setBill] = useState({});
  const [billHeader, setBillHeader] = useState({});

  return (
    <Grid container className={styles.contentWrapper} mt={0} rowGap={3}>
      <Grid item xs={12}>
        <h3>Bill & Currency</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Bill number prefix</h5>
            <TextFieldBGGray
              label="Enter bill numbre prefix"
              fullWidth
              value={bill?.prefix}
              onChange={(event) => {
                setBill({ ...bill, prefix: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Next bill number</h5>
            <TextFieldBGGray
              type="number"
              label="Enter next bill number"
              fullWidth
              value={bill?.next_bill_number}
              onChange={(event) => {
                setBill({ ...bill, next_bill_number: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Print format</h5>
            <TextFieldBGGray
              select
              label="Choose printer format"
              fullWidth
              value={bill?.printer_format}
              onChange={(event) => {
                setBill({ ...bill, printer_format: event.target.value });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h3>Bill header</h3>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Title</h5>
            <TextFieldBGGray
              label="Enter title"
              fullWidth
              value={billHeader?.title}
              onChange={(event) => {
                setBillHeader({ ...billHeader, title: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Address 1</h5>
            <TextFieldBGGray
              label="Enter address 1"
              fullWidth
              value={billHeader?.address1}
              onChange={(event) => {
                setBillHeader({ ...billHeader, address1: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Address 2</h5>
            <TextFieldBGGray
              label="Enter address 2"
              fullWidth
              value={billHeader?.address2}
              onChange={(event) => {
                setBillHeader({ ...billHeader, address2: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Address 3</h5>
            <TextFieldBGGray
              label="Enter address 3"
              fullWidth
              value={billHeader?.address3}
              onChange={(event) => {
                setBillHeader({ ...billHeader, address3: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Address 4</h5>
            <TextFieldBGGray
              label="Enter address 4"
              fullWidth
              value={billHeader?.address4}
              onChange={(event) => {
                setBillHeader({ ...billHeader, address4: event.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Address 4</h5>
            <TextFieldBGGray
              type="number"
              label="Enter tel. number"
              fullWidth
              value={billHeader?.mobile}
              onChange={(event) => {
                setBillHeader({ ...billHeader, mobile: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Footer 1</h5>
            <TextFieldBGGray
              label="Enter footer 1"
              fullWidth
              value={billHeader?.address4}
              onChange={(event) => {
                setBillHeader({ ...billHeader, footer1: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Footer 2</h5>
            <TextFieldBGGray
              label="Enter footer 2"
              fullWidth
              value={billHeader?.footer2}
              onChange={(event) => {
                setBillHeader({ ...billHeader, footer2: event.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <h5 className={styles.subtitle}>Footer 3</h5>
            <TextFieldBGGray
              label="Enter footer 3"
              fullWidth
              value={billHeader?.footer3}
              onChange={(event) => {
                setBillHeader({ ...billHeader, footer3: event.target.value });
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BillSettings;
