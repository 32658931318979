import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import styles from "./styles.module.css";
import Loader from "../../utils/Loading";
import UserService from "../../../services/UserService";
import { ROUTES, STORAGE_KEYS } from "../../../const";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../context/ShopContext";
import ShopService from "../../../services/ShopService";

function Shop() {
  const navigate = useNavigate();
  const { setSelectedShop, setShopId } = useContext(ShopContext);

  const [isLoading, setIsLoading] = useState(false);
  const [shops, setShops] = useState([]);

  const selectShopHandler = (shop) => {
    localStorage.setItem(STORAGE_KEYS.SHOP_ID, shop.id);
    localStorage.setItem(STORAGE_KEYS.SHOP_NAME, shop?.shop_name);
    setSelectedShop(shop);
    setShopId(shop?.id);
    navigate(ROUTES.COUNTER);
  };

  const getUserShops = async () => {
    setIsLoading(true);
    try {
      const resp = await UserService.getUserShops();
      if (resp.length === 1) {
        return selectShopHandler(resp[0]);
      }
      setShops(resp);
    } catch (error) {
      console.log("Error: ", error);
    }
    setIsLoading(false);
  };

  const getShopProfile = async () => {
    return await ShopService.loadShopProfile();
  };

  useEffect(() => {
    if (!localStorage.getItem(STORAGE_KEYS.TOKEN)) {
      navigate(ROUTES.LOGIN);
    }
    if (localStorage.getItem(STORAGE_KEYS.SHOP_ID)) {
      setSelectedShop(getShopProfile())
      navigate(ROUTES.COUNTER);
    }
    getUserShops();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Loader isOpen={isLoading} />
      <Grid container rowSpacing={6} className={styles.textArea}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <span className={styles.head2}>
                SELECT <span className={styles.head1}>SHOP</span>
              </span>
            </Grid>
            <Grid item xs={12} className={styles.text}>
              <span>Please select your shop to continue billing.</span>
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && (
          <Grid item xs={12}>
            <Grid container className={styles.selectWrapper}>
              {shops.map((shop) => (
                <Grid
                  key={shop?.id}
                  item
                  xs={12}
                  textAlign={"center"}
                  onClick={() => selectShopHandler(shop)}
                  className={styles.selectContainer}
                >
                  {shop?.shop_name}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Shop;
