import React, { useEffect, useState, useContext, useCallback } from "react";
import CustomerService from "../../../services/CustomerService";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import Loader from "../../utils/Loading";
import {
  Grid,
  InputAdornment,
  IconButton,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import TextFieldBGWhite from "../../utils/CustomFields/TextFieldBGWhite";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";
import DataTable from "../../utils/DataTable";
import Info from "../../utils/Alert/Info";
import { ROUTES } from "../../../const";
import RoundOff from "../../utils/RoundOff";
import validityExpired from "../../utils/validityExpired";
import RestrictionInfo from "../../utils/Alert/RestrictionInfo";
import CustomerSaleDialog from "../../popups/CustomerSaleDialog";
import DateTimeHelpers from "../../../helpers/DateTimeHelpers";
import ShopContext from "../../../context/ShopContext";
import SyncIcon from "@mui/icons-material/Sync";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CustomDatePicker from "../../utils/CustomFields/CustomDatePicker";
import AddPaymentToCustomerDialog from "../../popups/AddPaymentToCustomer";

function CustomerDetails() {
  const { selectedShop } = useContext(ShopContext);

  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [customerJournals, setCustomerJournals] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [customerJournalsPagination, setCustomerJournalsPagination] = useState({
    page: 0,
    offset: 0,
    limit: 20,
    count: 0,
  });
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 5))
  );
  const [toDate, setToDate] = useState(DateTimeHelpers.getMaxDate(new Date()));
  const [isShowGenerateButton, setIsShowGenerateButton] = useState(false);
  const [prevFilterData, setPrevFilterData] = useState({
    fromDate,
    toDate,
  });
  const [selectedSale, setSelectedSale] = useState(null);
  const [paymentTab, setPaymentTab] = useState(false);

  const navigate = useNavigate();
  const { customerId } = useParams();

  const moveToHomePage = () => {
    navigate(ROUTES.CUSTOMERS);
  };

  const getBalance = (data) => {
    const balance = RoundOff(Number(data?.balance));
    let balanceStyle;
    if (balance >= 0) balanceStyle = { color: "green" };
    else balanceStyle = { color: "red" };
    return (
      <Typography variant="body2" style={balanceStyle} gutterBottom>
        {balance}
      </Typography>
    );
  };

  const headerData = [
    {
      label: "Date",
      id: "Date",
      type: "callback",
      viewRender: (item) => {
        const date = new Date(item.time);
        return DateTimeHelpers.convertDateToIsoDMY(date);
      },
    },
    {
      label: "Time",
      id: "time",
      type: "callback",
      viewRender: (item) => {
        const date = item.time.split("T")[1].split(".")[0];
        return DateTimeHelpers.formatServerTimeSegment(date);
      },
    },
    {
      label: "Description",
      id: "description",
      type: "text",
    },
    {
      label: "Credit",
      id: "credit",
      type: "text",
    },
    {
      label: "Debit",
      id: "debit",
      type: "text",
    },
    {
      label: "Balance",
      id: "balance",
      type: "callback",
      viewRender: (data) => getBalance(data),
    },
  ];

  const getCustomerJournals = async ({
    _fromDate = fromDate,
    _toDate = toDate,
    _offset = customerJournalsPagination.offset,
    _limit = customerJournalsPagination.limit,
  } = {}) => {
    setLoadingIndicator(true);
    try {
      const timezoneFreeStartTime = new Date(
        _fromDate.getTime() - _fromDate.getTimezoneOffset() * 60000
      );
      const timezoneFreeEndTime = new Date(
        _toDate.getTime() - _toDate.getTimezoneOffset() * 60000
      );

      const params = {
        start_time: timezoneFreeStartTime,
        end_time: timezoneFreeEndTime,
        offset: _offset,
        limit: _limit,
        search: filterText,
      };

      const res = await CustomerService.getCustomerJournals(customerId, params);
      setCustomerJournals(res?.data);
      setCustomerJournalsPagination({
        ...customerJournalsPagination,
        count: res?.count,
      });
    } catch (error) {
      console.log(error);
    }
    setLoadingIndicator(false);
  };

  const getCustomerDetails = async () => {
    try {
      const res = await CustomerService.getCustomer(customerId);
      setCustomer(res);
    } catch (error) {
      console.log(error.message);
    }
  };

  const togglePaymentTab = () => setPaymentTab((prevValue) => !prevValue);

  const toggleViewSale = async (row) => {
    if (row === null || row?.sale_id === null) return setSelectedSale(null);
    setLoadingIndicator(true);
    try {
      const res = await CustomerService.getCustomerSaleDetails(row?.sale_id);
      setSelectedSale(res);
    } catch (error) {
      console.log(error.message);
    }
    setLoadingIndicator(false);
  };

  const addPaymentToCustomerHandler = async (data) => {
    setLoadingIndicator(true);
    try {
      await CustomerService.addPaymentToCustomer(customerId, data);
      togglePaymentTab();
      setCustomerJournalsPagination({
        ...customerJournalsPagination,
        page: 0,
        offset: 0,
      });
      getCustomerDetails();
      getCustomerJournals({ _offset: 0 });
    } catch (error) {
      console.log(error.message);
    }
    setLoadingIndicator(false);
  };

  const handleChangeCustomerJournalPage = (event, newPage) => {
    setCustomerJournalsPagination({
      ...customerJournalsPagination,
      page: newPage,
    });
    let offset = newPage * customerJournalsPagination.limit;
    setCustomerJournalsPagination({ ...customerJournalsPagination, offset });
    getCustomerJournals({ _offset: offset });
  };

  const handleChangeCustomerRowsPerPage = (event) => {
    const pageRowLimit = event.target.value;
    setCustomerJournalsPagination({
      ...customerJournalsPagination,
      limit: pageRowLimit,
    });
    getCustomerJournals({ _limit: pageRowLimit });
  };

  const handleSearch = () => {
    setCustomerJournalsPagination({
      ...customerJournalsPagination,
      page: 0,
      offset: 0,
    });
    getCustomerJournals({ _offset: 0 });
  };

  const generateCustomerDetails = () => {
    getCustomerJournals();
    setIsShowGenerateButton(false);
    setPrevFilterData({
      fromDate,
      toDate,
    });
  };

  const isAnyChangeOnCustomerDetailsFilters = useCallback(() => {
    return (
      fromDate !== prevFilterData.fromDate || toDate !== prevFilterData.toDate
    );
  }, [fromDate, toDate, prevFilterData.fromDate, prevFilterData.toDate]);

  useEffect(() => {
    if (isShowGenerateButton === null) return;
    setIsShowGenerateButton(isAnyChangeOnCustomerDetailsFilters());
  }, [isAnyChangeOnCustomerDetailsFilters, isShowGenerateButton]);

  useEffect(() => {
    getCustomerDetails();
    getCustomerJournals();
    //eslint-disable-next-line
  }, [customerId]);

  return (
    <>
      <Grid container className={styles.container} rowGap={2}>
        <Loader isOpen={loadingIndicator} />
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid display="flex" alignItems="center">
            <ArrowBackRoundedIcon
              className={styles.largeIcon}
              onClick={moveToHomePage}
            />
            <Grid className={styles.titleWrapper}>
              <h2>Customer Details</h2>
              <Grid display="flex" alignItems="center">
                <h5 style={{ color: "#8b8b8b" }}>Home</h5>
                <ArrowForwardRoundedIcon className={styles.smallIcon} />
                <h5>Customer Details</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <CloseRoundedIcon
              className={styles.largeIcon}
              onClick={moveToHomePage}
            />
          </Grid>
        </Grid>
        {selectedShop &&
        validityExpired(selectedShop.customer_support_validity) ? (
          <Grid item xs={12}>
            <RestrictionInfo
              title={"Customer Support Feature not available "}
              content={"To get Customer support options, contact support team."}
            />
          </Grid>
        ) : (
          <Grid item xs={12} className={styles.dataWrapper}>
            <Grid container rowGap={2}>
              <Grid item xs={12} className={styles.filterWrapper}>
                <Grid container rowGap={2}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <TextFieldBGWhite
                      label="search customer"
                      size="small"
                      value={filterText}
                      onChange={(event) => setFilterText(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                              onClick={handleSearch}
                            >
                              <SearchRoundedIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <ButtonBlue
                      style={{ padding: 6 }}
                      onClick={togglePaymentTab}
                    >
                      Add Payment
                    </ButtonBlue>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Grid container columnSpacing={2} rowSpacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        lg={4}
                        className={styles.dateItem}
                      >
                        <CalendarMonthIcon className={styles.icon} />
                        <h4>Start date:</h4>
                        <CustomDatePicker
                          value={fromDate}
                          onChange={(date) => setFromDate(date)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        lg={4}
                        className={styles.dateItem}
                      >
                        <CalendarMonthIcon className={styles.icon} />
                        <h4>End date:</h4>
                        <CustomDatePicker
                          value={toDate}
                          onChange={(date) => setToDate(date)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2} lg={4}>
                        <ButtonBlue
                          style={{ padding: 6, backgroundColor: "#00a65a" }}
                          onClick={generateCustomerDetails}
                          disabled={isShowGenerateButton === false}
                        >
                          <SyncIcon className={styles.actionBtnIcon} />
                          Generate
                        </ButtonBlue>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={styles.contentWrapper}>
                <Grid container spacing={1.5}>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Name</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>{customer?.name || ""}</h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Balance</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>{customer?.balance || 0}</h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Mobile</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>{customer?.mobile || "-"}</h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Credit limit</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>
                      {customer?.credit_limit || 0}
                    </h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>E-mail</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>{customer?.email || "-"}</h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Point</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>{customer?.point || 0}</h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Location</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>
                      {customer?.location || "-"}
                    </h4>
                  </Grid>
                  <Grid item xs={5} sm={2.5}>
                    <h4 className={styles.label}>Loyality card No</h4>
                  </Grid>
                  <Grid item xs={7} sm={3.5}>
                    <h4 className={styles.field}>
                      {customer?.loyalty_card_no || 0}
                    </h4>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {customerJournals && customerJournals.length ? (
                  <Paper>
                    <DataTable
                      columns={headerData}
                      rows={customerJournals}
                      rowClickListener={toggleViewSale}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50]}
                      component="div"
                      count={customerJournalsPagination.count}
                      rowsPerPage={customerJournalsPagination.limit}
                      page={customerJournalsPagination.page}
                      onPageChange={handleChangeCustomerJournalPage}
                      onRowsPerPageChange={handleChangeCustomerRowsPerPage}
                    />
                  </Paper>
                ) : (
                  !loadingIndicator && (
                    <Info
                      title={"No transactions to list"}
                      content={
                        "You have no transactions to list with current filter configuration. Please clear the filters or make some sale"
                      }
                    />
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {paymentTab && (
          <AddPaymentToCustomerDialog
            togglePaymentTab={togglePaymentTab}
            addPaymentToCustomer={addPaymentToCustomerHandler}
            currentBalance={customer?.balance || 0}
          />
        )}
        {selectedSale && (
          <CustomerSaleDialog
            closeDialog={() => setSelectedSale(null)}
            saleData={selectedSale}
          />
        )}
      </Grid>
    </>
  );
}

export default CustomerDetails;
