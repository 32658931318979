import React from "react";
import styles from "./styles.module.css";
import { Grid, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DataTable from "../utils/DataTable";
import ButtonBlue from "../utils/CustomFields/ButtonBlue";

function BillSummaryDialog({ handleClose, billData, columns }) {
  return (
    <div className={styles.popUp}>
      <Grid
        container
        className={styles.billContentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
        rowSpacing={1.5}
      >
        <Grid item xs={12} mb={2} display="flex" justifyContent="space-between">
          <h3>Bill Summary</h3>
          <CloseRoundedIcon onClick={handleClose} className={styles.icon} />
        </Grid>
        <Grid item xs={12} className={styles.dataContainer}>
          <Grid container rowSpacing={2} columnSpacing={1}>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Bill no:</h5>
              <h5>2024/A/234</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Date</h5>
              <h5>02/06/2024</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Order type</h5>
              <h5 className={styles.heighLite}>DINE IN</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Customer name</h5>
              <h5>Shabir muhammed</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Mobile no:</h5>
              <h5>7591986068</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Bill amount</h5>
              <h5 className={styles.heighLite}>409.50</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Type</h5>
              <h5>SALE</h5>
            </Grid>
            <Grid item sm={3} xs={6}>
              <h5 className={styles.subTitle}>Status</h5>
              <h5>Synced</h5>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h4>Items:</h4>
        </Grid>
        <Grid item xs={12} className={styles.tableContainer}>
          <DataTable columns={columns} rows={billData} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item sx={6} md={3}>
              <ButtonBlue fullWidth style={{ padding: 10 }}>
                Reset bill no
              </ButtonBlue>
            </Grid>
            <Grid item sx={6} md={3}>
              <ButtonBlue fullWidth style={{ padding: 10 }}>
                Invoice
              </ButtonBlue>
            </Grid>
            <Grid item sx={6} md={3}>
              <ButtonBlue fullWidth style={{ padding: 10 }}>
                Share
              </ButtonBlue>
            </Grid>
            <Grid item sx={6} md={3}>
              <ButtonBlue fullWidth style={{ padding: 10 }}>
                Buy back
              </ButtonBlue>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default BillSummaryDialog;
