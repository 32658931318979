import React, { useContext } from 'react';
import styles from './styles.module.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Grid, Button } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CartContext from '../../context/CartContext';

function BillEditDialog({ handleClose }) {
  const { activeSale } = useContext(CartContext);

  return (
    <div className={styles.popUp}>
      <div className={styles.contentWrapper}>
        <div className={styles.headInfoSec}>
          <Grid container>
            <Grid item xs={10} style={{ paddingTop: '15px' }}>
              <h3 style={{ color: '#222222' }} className={styles.priceTxt}>
                Amount
                <div className={styles.amountBlue}>
                  <CurrencyRupeeIcon className={styles.rupieeIcon} />
                  <h5>{activeSale.total}</h5>
                </div>
              </h3>
              <h4 style={{ color: '#8b8b8b' }} className={styles.priceTxt}>
                Tax:
                <div className={styles.amountGray} style={{ paddingRight: 10 }}>
                  <h4>{activeSale.tax || 0}</h4>
                </div>
                Cess:
                <div className={styles.amountGray}>
                  <h4>{activeSale.cess || 0}</h4>
                </div>
              </h4>
              <h4 style={{ color: '#8b8b8b' }} className={styles.priceTxt}>
                Discount:
                <div className={styles.amountGray}>
                  <h4>{activeSale?.discount_applied_on_items || 0}</h4>
                </div>
              </h4>
              <h3 style={{ color: '#222222' }} className={styles.priceTxt}>
                Total payable:
                <div className={styles.amountBlue}>
                  <CurrencyRupeeIcon style={{ fontSize: '16px' }} />
                  <h4>{activeSale.payable || 0}</h4>
                </div>
              </h3>
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <CloseRoundedIcon onClick={handleClose} className={styles.icon} />
            </Grid>
          </Grid>
        </div>
        <div className={styles.inputSec}>
          <Grid container spacing={1}>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>1</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>2</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>3</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h5>CBOX</h5>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h5>CUST</h5>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>4</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>5</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>6</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h5>ROUND OFF</h5>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h5>RE PRINT</h5>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>7</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>8</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>9</h3>
              </Button>
            </Grid>
            <Grid item xs={4.8}>
              <Button className={styles.btn}>
                <h5>KOT</h5>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h5>+/-</h5>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>0</h3>
              </Button>
            </Grid>
            <Grid item xs={2.4}>
              <Button className={styles.btn}>
                <h3>.</h3>
              </Button>
            </Grid>
            <Grid item xs={4.8}>
              <Button className={styles.btn}>
                <h5>BILL</h5>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default BillEditDialog;
