import React from "react";
import { styled } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const EditIcon = styled(ModeEditOutlineRoundedIcon)({
  color: "#8b8b8b",
  fontSize: "18px",
});

export default function CustomDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        slots={{
          openPickerIcon: EditIcon,
        }}
        sx={{
          "& fieldset": { border: "none" },
          "& .MuiInputBase-input": {
            padding: "6px 0 0 !important",
            textTransform: "lowercase",
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}