import React, { useContext } from "react";
import { Grid, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { ROUTES } from "../../../const";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";
import CartContext from "../../../context/CartContext";

function ActiveOrders() {
  const { cart } = useContext(CartContext)
  const navigate = useNavigate();

  const moveToHomePage = () => {
    navigate(ROUTES.SALES);
  };
  
  return (
    <Grid container className={styles.container} rowGap={2}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid display="flex" alignItems="center">
          <ArrowBackRoundedIcon
            className={styles.icon}
            onClick={moveToHomePage}
          />
          <Grid className={styles.titleWrapper}>
            <h2>Active orders</h2>
            <Grid display="flex" alignItems="center">
              <h5 style={{ color: "#8b8b8b" }}>Home</h5>
              <ArrowForwardRoundedIcon className={styles.smallIcon} />
              <h5>Active Orders</h5>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <CloseRoundedIcon className={styles.icon} onClick={moveToHomePage} />
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.contentWrapper}>
        <Grid container spacing={3}>
          {(cart || []).map((sale) => (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Grid container className={styles.item} rowSpacing={0.5}>
                <Grid item xs={12}>
                  <Grid display="flex" alignItems="center" marginBottom={0.5}>
                    <h5 className={styles.title}>Created:</h5>
                    <h5>{sale?.created_at}</h5>
                  </Grid>
                  <Grid display="flex" alignItems="center" marginBottom={0.5}>
                    <h5 className={styles.title}>Customer:</h5>
                    <h5>{sale?.customer}</h5>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} display="flex" alignItems="center">
                  <h5 className={styles.title}>Items:</h5>
                  <h5>{`${sale?.items.length}`}</h5>
                </Grid>
                <Grid item xs={12} className={styles.itemList}>
                  <Grid container rowSpacing={1}>
                    {sale?.items.map((item, index) => (
                      <>
                        <Grid item xs={6}>
                          <h5>{`${index + 1}. ${item.name}`}</h5>
                        </Grid>
                        <Grid item xs={3}>
                          <h5>{item.count}</h5>
                        </Grid>
                        <Grid item xs={3}>
                          <h5>{item.price}</h5>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ButtonBlue fullWidth style={{ padding: 6 }}>
                    PRINT BILL
                  </ButtonBlue>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ActiveOrders;
