import React from "react";
import { Grid } from "@mui/material";
import styles from "./styles.module.css";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ButtonGray from "../CustomFields/ButtonGray";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

function ProductsManage() {
  return (
    <Grid
      container
      columnSpacing={2}
      marginLeft={0}
      className={styles.contentWrapper}
    >
      <Grid item xs={2}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle}>
          <FileDownloadIcon className={styles.icon} /> Export categ.
        </ButtonGray>
      </Grid>
      <Grid item xs={2}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle}>
          <FileUploadIcon className={styles.icon} /> Import categ.
        </ButtonGray>
      </Grid>
      <Grid item xs={2}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle}>
          <NoteAddIcon className={styles.icon} /> Create categ.
        </ButtonGray>
      </Grid>
      <Grid item xs={2}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle}>
          <FileDownloadIcon className={styles.icon} /> Export Items
        </ButtonGray>
      </Grid>
      <Grid item xs={2}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle}>
          <FileUploadIcon className={styles.icon} /> Import items
        </ButtonGray>
      </Grid>
      <Grid item xs={2}>
        <ButtonGray fullWidth veriant="container" className={styles.btnStyle}>
          <NoteAddIcon className={styles.icon} /> Create items
        </ButtonGray>
      </Grid>
    </Grid>
  );
}

export default ProductsManage;
