import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextFieldBGTransparent from "../../utils/CustomFields/TextFieldBGTransparent";
import ButtonLightBlue from "../../utils/CustomFields/ButtonLightBlue";
import { ROUTES, STORAGE_KEYS } from "../../../const";
import Loader from "../../utils/Loading/index";
import AuthService from "../../../services/AuthService";

function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
    const nextBillNumber = localStorage.getItem(
      STORAGE_KEYS.BILL_NUMBER_SUFFIX
    );
    const shop = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
    const counter = localStorage.getItem(STORAGE_KEYS.COUNTER);
    const shift = localStorage.getItem(STORAGE_KEYS.SHIFT);

    if (token && shop && counter && nextBillNumber && shift) {
      navigate(ROUTES.SALES);
    } else if (token && shop && counter && nextBillNumber) {
      navigate(ROUTES.SHIFT);
    } else if (token && shop && counter) {
      navigate(ROUTES.BILL_NUMBER);
    } else if (token && shop) {
      navigate(ROUTES.COUNTER);
    } else if (token) {
      navigate(ROUTES.SELECT_SHOP);
    }
    //eslint-disable-next-line
  }, []);

  const onClickLoginButton = async (e) => {
    setErrorMessage(null);
    if (!username || !password) {
      setErrorMessage("Username and Password should not be empty..");
      return;
    }
    setIsLoading(true);
    const error = await AuthService.login(username, password);
    setIsLoading(false);
    if (error) {
      setErrorMessage("Failed to login!");
    } else {
      navigate(ROUTES.SELECT_SHOP);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      <Loader isOpen={isLoading} />
      <Grid container rowSpacing={5} className={styles.textArea}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <span className={styles.head1}>SANOFT</span>
              {"  "}
              <span className={styles.head2}>POS</span>
            </Grid>
            <Grid item xs={12} className={styles.text}>
              <span>
                Kindly proceed by logging into your account to seamlessly
                continue with the billing process.
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TextFieldBGTransparent
                id="outlined-basic"
                label="Enter username"
                variant="outlined"
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGTransparent
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon style={{ color: "#8a8a8a" }} />
                        ) : (
                          <VisibilityIcon style={{ color: "#8a8a8a" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label="Enter password"
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className={styles.errorMsg}
            >
              {errorMessage && <span>{errorMessage}</span>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <ButtonLightBlue
                variant="contained"
                onClick={onClickLoginButton}
                fullWidth
              >
                Submit
              </ButtonLightBlue>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;
