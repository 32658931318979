import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const TextFieldBGTransparent = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInputLabel-root": {
    color: "rgba(256, 256, 256, 0.6)",
    fontSize: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #1976d2",
      borderRadius: 30,
    },
    "&:hover fieldset": {
      borderColor: "#1976d2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1976d2",
    },
    "& .MuiInputBase-input": {
      color: "#f9f9f9",
      backgroundColor: "transparent",
      fontSize: "14px",
    },
  },
});

export default TextFieldBGTransparent;
