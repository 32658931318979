import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const ButtonBlue = styled(Button)({
  backgroundColor: "#0160C9",
  borderRadius: "13px",
  fontSize: "14px",
  padding: "0 7px",
  minHeight: "0px !important",
  color: "white",
  "&:hover": {
    backgroundColor: "rgba(1, 96, 201, 0.8)",
  },
});

export default ButtonBlue;
