import React, { useEffect, useState, useContext, useMemo } from "react";
import Loader from "../../utils/Loading";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import WarningMsg from "../../utils/WarningMsg/WarningMsg";
import { ContactSupportTeamWhatsapp } from "../../utils/ContactSupportTeam";
import validityExpired from "../../utils/validityExpired";
import ShopContext from "../../../context/ShopContext";

function KOT() {
  const { selectedShop } = useContext(ShopContext);

  const [loading, setLoading] = useState();
  const [salespersons, setSalespersons] = useState([]);
  const [tables, setTables] = useState([]);



  const scaleValidity = useMemo(
    () => validityExpired(selectedShop?.smart_scale_validity),
    [selectedShop?.smart_scale_validity]
  );

  useEffect(() => {
    setSalespersons([
      "jeslin musthafa",
      "jabir",
      "sakkeer",
      "jeslin musthafa",
      "jabir",
      "sakkeer",
      "jeslin musthafa",
      "jabir",
      "sakkeer",
      "jeslin musthafa",
      "jabir",
      "sakkeer",
      "jeslin musthafa",
      "jabir",
      "sakkeer",
      "jeslin musthafa",
      "jabir",
      "sakkeer",
    ])
    setTables([
      "table 1",
      "table 2",
      "table 3",
      "table 4",
      "table 5",
      "take away",
    ])
    setLoading(false)
  }, []);

  return (
    <>
      <Grid container className={styles.contentWrapper} mt={0} rowGap={2}>
        <Loader isOpen={loading} />
        {scaleValidity ? (
          <WarningMsg
            title="no subscription"
            message={`You don't have a scale subscription yet`}
            btnName="contact support"
            onClickHandler={ContactSupportTeamWhatsapp}
          />
        ) : (
          <>
            <Grid item xs={12}>
              <h3>KOT</h3>
            </Grid>
            <Grid item xs={12} className={styles.dataWrapper}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid className={styles.container}>
                    <h3>KOT Server Running</h3>
                    <Grid container mt={2} columnSpacing={2}>
                      <Grid item xs={8} md={9} lg={10}>
                        <Grid container>
                          <Grid item className={styles.copyField}>
                            <h4>Host: http://192.168.1.34:8000</h4>
                          </Grid>
                          <Grid item className={styles.copyBtn}>
                            <FileCopyIcon className={styles.icon} /> Copy
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} md={3} lg={2}>
                        <ButtonBlue fullWidth style={{ padding: "8px 0px" }}>
                          Stop Server
                        </ButtonBlue>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid className={styles.itemContainer}>
                    <h3>Sales persons</h3>
                    <h5>We have 3 sales presons available</h5>
                    <Grid
                      container
                      spacing={2}
                      className={styles.itemWrapper}
                      mt={1}
                      mb={2}
                    >
                      {salespersons.map((salesperson) => (
                        <Grid item xs={12} md={6}>
                          <Grid className={styles.item}>
                            <h5>{salesperson}</h5>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <ButtonBlue fullWidth style={{ padding: "8px" }}>
                      Reload
                    </ButtonBlue>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid className={styles.itemContainer}>
                    <h3>Tables and Chairs</h3>
                    <h5>We have 6 tables available</h5>
                    <Grid
                      container
                      spacing={2}
                      className={styles.itemWrapper}
                      mt={1}
                      mb={2}
                    >
                      {tables.map((table) => (
                        <Grid item xs={6}>
                          <Grid className={styles.item}>
                            <h5>{table}</h5>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <ButtonBlue fullWidth style={{ padding: "8px" }}>
                      Reload
                    </ButtonBlue>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default KOT;
