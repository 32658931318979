import { STORAGE_KEYS, TAX_PREFERENCES } from "../const";
import RoundOff from "../components/utils/RoundOff";
import CartManager from "./CartManager";

class TaxManager {
  getTaxPreference = () => {
    return localStorage.getItem(STORAGE_KEYS.TAX_PREFERENCE);
  };

  getItemUnitRate = (item, cartItem = {}) => {
    const { tax, cess } = item;
    const price = cartItem?.price ?? item.price
    const taxPreference = this.getTaxPreference();

    if (taxPreference === TAX_PREFERENCES.INCLUDING_TAX) {
      let taxDeducted = (price * 100) / (tax + 100);
      return RoundOff((taxDeducted * 100) / (cess + 100));
    }
    return price;
  };

  getItemUnitRateWithTax = (item) => {
    const { price, tax, cess } = item;
    const taxPreference = this.getTaxPreference();

    if (taxPreference === TAX_PREFERENCES.INCLUDING_TAX) {
      let priceWithCess = (price * (cess + 100)) / 100;
      return RoundOff((priceWithCess * (tax + 100)) / 100);
    }
    return price;
  };

  getItemCess = (item) => {
    const price = CartManager.getItemAmountWithDiscount(item)
    const cess = price * item.cess / 100
    return RoundOff(cess);
  };

  getItemTax = (item) => {
    const price = CartManager.getItemAmountWithDiscount(item) + this.getItemCess(item)
    const taxPreference = this.getTaxPreference();
    let tax = 0;
    if (taxPreference === TAX_PREFERENCES.INCLUDING_TAX || taxPreference === TAX_PREFERENCES.EXCLUDING_TAX) {
      tax = price * item.tax / 100
    }
    return RoundOff(tax);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TaxManager();
