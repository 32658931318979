import React from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

export default function Loader({ isOpen, size, backdropColor }) {
  if (!isOpen) {
    return <></>;
  }

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: 'absolute',
        backgroundColor: backdropColor || 'rgba(0,0,0,0.5)',
      }}
      open={true}
    >
      <CircularProgress
        size={size || 36}
        sx={{
          top: -6,
          left: -6,
          zIndex: 1,
          color: '#FF851B',
        }}
      />
    </Backdrop>
  );
}
