import React, { useEffect, useState } from "react";
import Loader from "../../utils/Loading";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TextFieldBGTransparent from "../../utils/CustomFields/TextFieldBGTransparent";
import ButtonLightBlue from "../../utils/CustomFields/ButtonLightBlue";
import styles from "./styles.module.css";
import { ROUTES, STORAGE_KEYS } from "../../../const";
import ShopService from "../../../services/ShopService";

function BillNumber() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [billNoPrefix, setBillNoPrefix] = useState(null);
  const [billNoSuffix, setBillNoSuffix] = useState(null);
  const [billNo, setBillNo] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const storeNextBIllNumber = () => {
    if (!billNoPrefix || !billNoSuffix) {
      setErrorMessage(
        "Bill number prefix and Next bill number should not be empty.."
      );
      return;
    }
    setErrorMessage(null);
    localStorage.setItem(STORAGE_KEYS.BILL_NUMBER_PREFIX, billNoPrefix);
    localStorage.setItem(STORAGE_KEYS.BILL_NUMBER_SUFFIX, billNoSuffix);
    navigate(ROUTES.SHIFT);
  };

  const getHeighestBillNumber = async () => {
    setIsLoading(true);
    try {
      const resp = await ShopService.getHeighestBillNumber({
        prefix: billNoPrefix,
      });
      setBillNoSuffix(resp?.highest_bill_no + 1);
      setBillNo(true);
    } catch (error) {
      console.log(error.message);
    }
    setIsLoading(false);
  };

  const getSuggestedBillNoPrefix = () => {
    setBillNoPrefix(`${new Date().getFullYear()}/T/`);
  };

  useEffect(() => {
    getSuggestedBillNoPrefix();
  }, []);

  return (
    <>
      <Loader isOpen={isLoading} />
      <Grid container rowSpacing={5} className={styles.textArea}>
        <Grid item xs={12}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
              <span className={styles.head1}>BILL-NUMBER <span className={styles.head2}>SETUP</span></span>
            </Grid>
            <Grid item xs={12} className={styles.text}>
              <span>Please set the next bill number for your next sale.</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TextFieldBGTransparent
                id="prefix"
                label="Bill number prefix"
                variant="outlined"
                value={billNoPrefix}
                onChange={(e) => setBillNoPrefix(e.target.value)}
                fullWidth
              />
            </Grid>
            {billNo && (
              <Grid item xs={12}>
                <TextFieldBGTransparent
                  id="number"
                  label="Next bill number"
                  variant="outlined"
                  value={billNoSuffix}
                  onChange={(e) => setBillNoSuffix(e.target.value)}
                  fullWidth
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              className={styles.errorMsg}
            >
              {errorMessage && <span>{errorMessage}</span>}
            </Grid>
          </Grid>
        </Grid>
        {!billNo && (
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <ButtonLightBlue
                  variant="contained"
                  className={styles.btn}
                  onClick={getHeighestBillNumber}
                  fullWidth
                  disabled={!billNoPrefix}
                >
                  Next
                </ButtonLightBlue>
              </Grid>
            </Grid>
          </Grid>
        )}
        {billNo && (
          <Grid item xs={12}>
            <Grid container rowSpacing={1}>
              <Grid item xs={12}>
                <ButtonLightBlue
                  variant="contained"
                  onClick={storeNextBIllNumber}
                  fullWidth
                >
                  Submit
                </ButtonLightBlue>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default BillNumber;
