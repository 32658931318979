import React, { useState } from "react";
import styles from "./styles.module.css";
import { Grid, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { ROUTES } from "../../../const";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CustomDatePicker from "../../utils/CustomFields/CustomDatePicker";
import DescriptionIcon from "@mui/icons-material/Description";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SyncIcon from "@mui/icons-material/Sync";
import DataTable from "../../utils/DataTable";
import BillSummaryDialog from "../../popups/BillSummaryDialog";
import CustomTimePicker from "../../utils/CustomFields/CustomTimePicker";
import DateTimeHelper from "../../../helpers/DateTimeHelpers";

const salesReport = [
  {
    bill_no: "2023/A/434",
    date: "29/03/2023",
    time: "03: 07 AM",
    name: "Jeslin musthafa",
    mobile: "7591986068",
    total: 878.5,
    synced: true,
    type: "sale",
  },
  {
    bill_no: "2023/A/458",
    date: "29/03/2023",
    time: "03: 094 AM",
    name: "Jeslin musthafa",
    mobile: "7591986068",
    total: 984.5,
    synced: false,
    type: "sale",
  },
];

const sale = [
  {
    id: 1,
    name: "Apple",
    rate: 45,
    quantity: 1,
    price: 45,
  },
  {
    id: 2,
    name: "Mango",
    rate: 30,
    quantity: 1,
    price: 30,
  },
  {
    id: 3,
    name: "watermelon",
    rate: 20,
    quantity: 3,
    price: 60,
  },
];

function SalesReport() {
  const [filterFromDate, setFiterFromDate] = useState(
    DateTimeHelper.filterStartTime()
  );
  const [filterToDate, setFilterToDate] = useState(
    DateTimeHelper.filterEndTime()
  );
  const [saleDetails, setSaleDetails] = useState("");

  const navigate = useNavigate();

  const moveToHomePage = () => {
    navigate(ROUTES.SALES);
  };

  const getStatus = (data) => {
    const status = data?.synced;
    let style;
    if (status === true) style = { color: "#14A600" };
    else style = { color: "#FF0404" };
    return (
      <Typography variant="body2" style={style} gutterBottom>
        {status === true ? "Synced" : "Not synced"}
      </Typography>
    );
  };

  const headerData = [
    {
      label: "Bill No",
      id: "bill_no",
      type: "text",
    },
    {
      label: "Date",
      id: "date",
      type: "callback",
      viewRender: (item) => {
        return item.date;
      },
    },
    {
      label: "Time",
      id: "time",
      type: "callback",
      viewRender: (item) => {
        return item.time;
      },
    },
    {
      label: "Name",
      id: "name",
      type: "text",
    },
    {
      label: "Mobile",
      id: "mobile",
      type: "text",
    },
    {
      label: "Synced",
      id: "synced",
      type: "callback",
      viewRender: (data) => getStatus(data),
    },
    {
      label: "Type",
      id: "type",
      type: "text",
    },
  ];

  const billSummaryColumns = [
    {
      label: "SI NO",
      id: "id",
      type: "text",
    },
    {
      label: "Item name",
      id: "name",
      type: "text",
    },
    {
      label: "Rate/kg",
      id: "rate",
      type: "text",
    },
    {
      label: "Qty",
      id: "quantity",
      type: "text",
    },
    {
      label: "Rate",
      id: "price",
      type: "text",
    },
  ];

  return (
    <Grid container className={styles.container}>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid display="flex" alignItems="center">
          <ArrowBackRoundedIcon
            className={styles.iconLarge}
            onClick={moveToHomePage}
          />
          <Grid className={styles.titleWrapper}>
            <h2>Sales report</h2>
            <Grid display="flex" alignItems="center">
              <h5 style={{ color: "#8b8b8b" }}>Home</h5>
              <ArrowForwardRoundedIcon className={styles.smallIcon} />
              <h5>Sales Report</h5>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <CloseRoundedIcon className={styles.iconLarge} onClick={moveToHomePage} />
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.contentWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid container rowSpacing={1.5}>
                <Grid item xs={12}>
                  <h2>Sales summary</h2>
                </Grid>
                <Grid item xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item xs={2.5} className={styles.dateItem}>
                      <CalendarMonthIcon className={styles.icon} />
                      <h4>Start date:</h4>
                      <CustomDatePicker
                        value={filterFromDate}
                        onChange={(date) => setFiterFromDate(date)}
                      />
                    </Grid>
                    <Grid item xs={2.5} className={styles.dateItem}>
                      <WatchLaterIcon className={styles.icon} />
                      <h4>Start time:</h4>
                      <CustomTimePicker
                        value={filterFromDate}
                        onChange={(date) => setFiterFromDate(date)}
                      />
                    </Grid>
                    <Grid item xs={2.5} className={styles.dateItem}>
                      <CalendarMonthIcon className={styles.icon} />
                      <h4>End date:</h4>
                      <CustomDatePicker
                        value={filterToDate}
                        onChange={(date) => setFilterToDate(date)}
                      />
                    </Grid>
                    <Grid item xs={2.5} className={styles.dateItem}>
                      <WatchLaterIcon className={styles.icon} />
                      <h4>End time:</h4>
                      <CustomTimePicker
                        value={filterToDate}
                        onChange={(date) => setFilterToDate(date)}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <ButtonBlue fullWidth style={{ padding: 8 }}>
                        GENERATE REPORT
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container columnSpacing={3} rowSpacing={2}>
                    <Grid item lg={3} md={6} xs={12}>
                      <Grid className={styles.summaryCard}>
                        <Grid
                          className={styles.iconWrapper}
                          sx={{ backgroundColor: "#FF9E57" }}
                        >
                          <DescriptionIcon className={styles.largeIcon} />
                        </Grid>
                        <Grid className={styles.summaryText}>
                          <h4>Number of bills</h4>
                          <h3>234</h3>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                      <Grid className={styles.summaryCard}>
                        <Grid
                          className={styles.iconWrapper}
                          sx={{ backgroundColor: "#55CFF8" }}
                        >
                          <RequestQuoteIcon className={styles.largeIcon} />
                        </Grid>
                        <Grid className={styles.summaryText}>
                          <h4>Total bill amount</h4>
                          <h3>34509.06</h3>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                      <Grid className={styles.summaryCard}>
                        <Grid
                          className={styles.iconWrapper}
                          sx={{ backgroundColor: "#AF7EEC" }}
                        >
                          <ReceiptLongIcon className={styles.largeIcon} />
                        </Grid>
                        <Grid className={styles.summaryText}>
                          <h4>Total expenses</h4>
                          <h3>24533.24</h3>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                      <Grid className={styles.summaryCard}>
                        <Grid
                          className={styles.iconWrapper}
                          sx={{ backgroundColor: "#759ED9" }}
                        >
                          <MonetizationOnIcon className={styles.largeIcon} />
                        </Grid>
                        <Grid className={styles.summaryText}>
                          <h4>Cash balance</h4>
                          <h3>909.4</h3>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid className={styles.itemContainer}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <h2>Sales summary</h2>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ButtonBlue fullWidth style={{ padding: 10 }}>
                        Item summart
                      </ButtonBlue>
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonBlue fullWidth style={{ padding: 10 }}>
                        Sales summary
                      </ButtonBlue>
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonBlue fullWidth style={{ padding: 10 }}>
                        Close counter
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid className={styles.itemContainer}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <h2>Reports</h2>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <ButtonBlue fullWidth style={{ padding: 10 }}>
                        Bill summary
                      </ButtonBlue>
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonBlue fullWidth style={{ padding: 10 }}>
                        Item summary
                      </ButtonBlue>
                    </Grid>
                    <Grid item xs={4}>
                      <ButtonBlue fullWidth style={{ padding: 10 }}>
                        Itemised bill
                      </ButtonBlue>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <Grid container rowSpacing={2}>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <h2>Reports</h2>
                    <ButtonBlue style={{ padding: 8 }}>
                      <SyncIcon style={{ marginRight: 5 }} /> Sync sales
                    </ButtonBlue>
                  </Grid>
                  <Grid item xs={12}>
                    <DataTable
                      columns={headerData}
                      rows={salesReport}
                      rowClickListener={(row) => setSaleDetails(row)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {saleDetails && (
        <BillSummaryDialog
          billData={sale}
          handleClose={() => {
            setSaleDetails("");
          }}
          columns={billSummaryColumns}
        />
      )}
    </Grid>
  );
}

export default SalesReport;
