import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import styles from './styles.module.css';
import ButtonBlue from '../CustomFields/ButtonBlue';
import CartItem from './CartItem';
import CartContext from '../../../context/CartContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function Cart({ cartItemEdit, invoiceEditHandler }) {
  const { activeSale, handleAddItemToSale, handleReduceItemFromSale } = useContext(CartContext);

  return (
    <Grid container className={styles.CartWrapper}>
      <Grid item xs={12} className={styles.saleInfo}>
        <Grid container>
          <Grid item xs={7} display={'flex'} flexDirection={'column'}>
            <h6>Cachier</h6>
            <h5>Sakkeer hussain</h5>
          </Grid>
          <Grid item xs={5} display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
            <h6>Table/Chair</h6>
            <h5>T9/4</h5>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.cartItemList}>
        <Grid container>
          {activeSale.items.length ? (
            (activeSale.items || []).map((cartItem) => (
              <CartItem
                item={cartItem}
                editItem={cartItemEdit}
                removeItem={handleReduceItemFromSale}
                addItem={handleAddItemToSale}
              />
            ))
          ) : (
            <Grid item xs={12} p={2} display="flex" justifyContent="center">
              <span className={styles.description}>Active cart is empty.</span>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.totalWrapper}>
        <Grid container rowSpacing={1} className={styles.priceContainer}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <h5 style={{ color: '#8b8b8b' }}>Items({activeSale.items.length})</h5>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                <CurrencyRupeeIcon className={styles.rupieeIcon} />
                <h5>{activeSale?.total || 0}</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <h5 style={{ color: '#8b8b8b' }}>Discount</h5>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                <CurrencyRupeeIcon className={styles.rupieeIcon} />
                <h5>{activeSale?.discount_applied_on_items}</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <h5 style={{ color: '#8b8b8b' }}>Tax</h5>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                <CurrencyRupeeIcon className={styles.rupieeIcon} />
                <h5>{activeSale?.tax}</h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <h5 style={{ color: '#8b8b8b' }}>Cess</h5>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                <CurrencyRupeeIcon className={styles.rupieeIcon} />
                <h5>{activeSale?.cess}</h5>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1}>
          <Grid item xs={6}>
            <h5 style={{ color: '#8b8b8b' }}>Sub Total</h5>
          </Grid>
          <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
            <CurrencyRupeeIcon className={styles.rupieeIcon} />
            <h5>{activeSale?.payable}</h5>
          </Grid>
          <Grid item xs={9}>
            <ButtonBlue fullWidth className={styles.billBtn} onClick={() => invoiceEditHandler()}>
              PRINT INVOICE
            </ButtonBlue>
          </Grid>
          <Grid item xs={3}>
            <ButtonBlue
              className={styles.billBtn}
              style={{ padding: '5px 15px !important' }}
              onClick={() => invoiceEditHandler()}
            >
              <MoreVertIcon />
            </ButtonBlue>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Cart;
