import BackendService from './BackendService';
import { HEADERS, STORAGE_KEYS, APP_VERSION } from '../const';

async function login(username, password) {
  const reqData = { username, password };
  try {
    const resp = await BackendService.postData('/login/', reqData, {
      'User-Interface': 'ios',
    });
    if (!resp.token) {
      return new Error('Failed generate auth token from server');
    }
    localStorage.setItem(STORAGE_KEYS.TOKEN, resp.token);
    localStorage.setItem(STORAGE_KEYS.APP_VERSION, APP_VERSION)
  } catch (error) {
    return error;
  }
}

async function logout(data = {}) {
  const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
  localStorage.clear();

  if (!token) return;
  try {
    return BackendService.destroy('/logout/', null, {
      [HEADERS.AUTH]: `Token ${token}`,
    });
  } catch (error) {
    console.error(error);
  }
}
async function resetPassword(data) {
  return BackendService.postData('/resetpassword/', data);
}
async function createAccount(data) {
  return BackendService.postData('/register/', data);
}

const findCountryCode = () => {
  return BackendService.getData('/public/country-code/');
};

const AuthService = {
  login,
  logout,
  resetPassword,
  createAccount,
  findCountryCode,
};

export default AuthService;
