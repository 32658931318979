export const BACKEND_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api-pos-legacy.sanoft.com/api";

export const ROUTES = {
  LOGIN: "/login",
  LOCK_SCREEN: "/lock",
  BILL_NUMBER: "/bill-number",
  SHIFT: "/shift",
  SELECT_SHOP: "/shops",
  COUNTER: "/counter",
  SALES: "/sales",
  ACTIVE_ORDERS: "/active-orders",
  SALES_REPORT: "/sales-reports",
  PAYPAGE: "/payment",
  CUSTOMERS: "/customers",
  CUSTOMER_DETAILS: "/customers/:customerId",
  CATEGORY: "/manage/category",
  PRODUCTS: "/manage/category/:categoryId",
  LABEL_SCALE: "/manage/label-scale",
  KOT: "/manage/kot",
  SALESPERSON: "/manage/salesperson",
  SETTINGS: "/manage/settings",
  PRINTERS: "manage/printers",
  BILL_CURRENCY: "/manage/settings/bill&currency",
  FEATURES: "/manage/settings/features",
  TAX: "/manage/settings/tax",
  BASIC_INFO: "/manage/settings/basic-info",
};

export const STORAGE_KEYS = {
  TOKEN: "pos-auth-token",
  APP_VERSION: "pos-app-version",
  USER_ID: "pos-auth-user-id",
  SHOP_ID: "pos-selected-shop-id",
  USERNAME: "pos-username",
  USER_TYPE: "pos-user-type",
  SHOP_NAME: "pos-shop-name",
  BILL_NUMBER_PREFIX: "pos-bill-number-prefix",
  BILL_NUMBER_SUFFIX: "pos-bill-number-suffix",
  SHIFT: "pos-shift-name",
  TERMINAL: "pos-terminal-id",
  TERMINAL_AUTH_TOKEN: "pos-terminal-auth-token",
  SALESPERSON_LOGIN: "pos-salesperson-login",
  TAX_PREFERENCE: "pos-tax-preference",
  TAX_REGION: 'pos-tax-region',
};

export const APP_VERSION = '2.6.12';

export const HEADERS = {
  AUTH: "Authorization",
  SHOP_ID: "shop-id",
  APP_VERSION: "App-Version",
};

export const CONTACT_NUMBER = "919746393923";

export const ORDER_YTPES = ["Take away", "Dine in", "Delivery", "Online"];

export const INVOICE_PRINTER = {
  type: ["USB", "network", "bluetooth", "sunmi", "PDF", "whatsapp"],
  invoiceFormat: ["3 inch image", "3 inch", "2 inch image", "2 inch"],
  kotFomat: [
    "3 inch image",
    "3 inch",
    "2 inch sunmi",
    "2 inch",
    "3 inch invoice",
    "2 inch invoice",
    "3 inch image invoice",
  ],
};

export const BARCODE_PRINTER = {
  type: ["USB", "network"],
  barcodeFormat: ["38 X 25", "50 X 25"],
};


export const TAX_PREFERENCES = {
  INCLUDING_TAX: 'Including Tax',
  EXCLUDING_TAX: 'Excluding Tax',
  NO_TAX: 'No Tax'
}