import { createContext, useContext, useState } from 'react';
import { STORAGE_KEYS } from '../const';
import CartManager from '../helpers/CartManager';
import ProductContext from './ProductContext';

const CartContext = createContext();

export function CartProvider(props) {
  const { items } = useContext(ProductContext);
  const handleInitialSaleData = () => {
    const bill_no_prefix = localStorage.getItem(STORAGE_KEYS.BILL_NUMBER_PREFIX);
    const bill_no_suffix = localStorage.getItem(STORAGE_KEYS.BILL_NUMBER_SUFFIX);

    return {
      bill_no: `${bill_no_prefix}${bill_no_suffix}`,
      bare_bill_no: Number(bill_no_suffix),
      items: [],
      payable: 0,
      discount_applied_on_items: 0,
      cess: 0,
      tax: 0,
    };
  };

  const [cart, setCart] = useState([]);
  const [activeSale, setActiveSale] = useState(handleInitialSaleData());

  const handleAddItemToSale = (item, quantity = null) => {
    let updatingItem = CartManager.getUpdadingItem(items, item);
    const cartItemIndex = activeSale.items.findIndex((i) => i.id === item.id);
    const updatingCartItem = cartItemIndex !== -1 ? activeSale.items[cartItemIndex] : {};
    const qty = quantity !== null ? quantity : cartItemIndex !== -1 ? updatingCartItem.qty + 1 : 1;

    cartItemUpdateHandler(updatingItem, updatingCartItem, cartItemIndex, qty);
  };

  const handleReduceItemFromSale = (item, quantity) => {
    let updatingItem = CartManager.getUpdadingItem(items, item);
    const cartItemIndex = activeSale.items.findIndex((i) => i.id === item.id);
    const updatingCartItem = activeSale.items[cartItemIndex];
    if (cartItemIndex === -1) return;

    const qty = quantity ?? updatingCartItem?.qty - 1;

    cartItemUpdateHandler(updatingItem, updatingCartItem, cartItemIndex, qty);
  };

  const handleClearItemQty = (item) => {
    handleReduceItemFromSale(item, -1);
  };

  const cartItemUpdateHandler = (updatingItem, cartItem, cartItemIndex, qty) => {
    let updatedCartItems = [...activeSale.items];
    let updatedItemData = { id: updatingItem.id };
    if (qty === 0) {
      handleRemoveItemFromSale(cartItem, cartItemIndex);
      return;
    } else if (qty === -1) {
      updatedItemData = { id: updatingItem.id, ...CartManager.getCartItemData(updatingItem, cartItem, 0) };
      updatedCartItems[cartItemIndex] = updatedItemData;
    } else {
      updatedItemData = { id: updatingItem.id, ...CartManager.getCartItemData(updatingItem, cartItem, qty) };
      cartItemIndex !== -1
        ? (updatedCartItems[cartItemIndex] = updatedItemData)
        : updatedCartItems.push(updatedItemData);
    }

    updateActiveSale(updatedCartItems);
  };

  const handleRemoveItemFromSale = (cartItem, cartItemIndex = null) => {
    const updatingItemIndex = cartItemIndex ?? CartManager.getCartItemIndex(activeSale.items, cartItem);
    if (updatingItemIndex === -1) return;
    let updatedCartItems = [...activeSale.items];
    updatedCartItems.splice(updatingItemIndex, 1);
    updateActiveSale(updatedCartItems);
  };

  const handleSetItemDiscount = (cartItem, discount) => {
    const updatingItem = CartManager.getUpdadingItem(items, cartItem);
    const cartItemIndex = CartManager.getCartItemIndex(activeSale.items, cartItem);
    let updatedCartItems = [...activeSale.items];
    updatedCartItems[cartItemIndex] = {
      ...cartItem,
      ...CartManager.getCartItemData(updatingItem, cartItem, null, discount),
    };
    updateActiveSale(updatedCartItems);
  };

  const handleClearItemDiscount = (cartItem) => {
    handleSetItemDiscount(cartItem, 0);
  };

  const handleSetItemPrice = (cartItem, price) => {
    let updatingItem = CartManager.getUpdadingItem(items, cartItem);
    const cartItemIndex = CartManager.getCartItemIndex(activeSale.items, cartItem);
    const updatedItemData = {
      id: updatingItem.id,
      ...CartManager.getCartItemData(updatingItem, { ...cartItem, price }),
    };
    let updatedCartItems = [...activeSale.items];
    updatedCartItems[cartItemIndex] = { ...updatedItemData, price };
    updateActiveSale(updatedCartItems);
  };

  const handleClearItemPrice = (cartItem) => {
    const updatingItem = CartManager.getUpdadingItem(items, cartItem);
    handleSetItemPrice(cartItem, updatingItem?.price);
  };

  const updateActiveSale = (updatedItems) => {
    setActiveSale((prevData) => ({
      ...prevData,
      items: updatedItems,
      total: CartManager.getTotalAmount(updatedItems),
      payable: CartManager.getTotalWithDiscountAndTaxes(updatedItems),
      cess: CartManager.getTotalCessAmount(updatedItems),
      tax: CartManager.getTotalTaxAmount(updatedItems),
      discount_applied_on_items: CartManager.getTotalDiscountAmount(updatedItems),
    }));
  };

  const handleSaleMoveToCart = () => {
    setCart([...cart, activeSale]);
    localStorage.setItem(
      STORAGE_KEYS.BILL_NUMBER_SUFFIX,
      Number(localStorage.getItem(STORAGE_KEYS.BILL_NUMBER_SUFFIX)) + 1
    );
    setActiveSale(handleInitialSaleData());
  };

  const handleSaleFetchFromCart = (index) => {
    const sale = cart[index];
    const updatedCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
    setActiveSale(sale);
    setCart(updatedCart);
  };

  const handleClearActiveSale = () => {
    setActiveSale(handleInitialSaleData());
  };

  const handleClearSaleFromCart = (index) => {
    const updatedCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
    setCart(updatedCart);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        activeSale,
        setActiveSale,
        handleAddItemToSale,
        handleReduceItemFromSale,
        handleSaleMoveToCart,
        handleSaleFetchFromCart,
        handleClearSaleFromCart,
        handleClearActiveSale,
        handleClearItemQty,
        handleRemoveItemFromSale,
        handleSetItemDiscount,
        handleClearItemDiscount,
        handleSetItemPrice,
        handleClearItemPrice,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}

export default CartContext;
