import React from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import CustomFormSwitch from "../../utils/CustomFields/CustomFormSwitch";

function Tax() {
  return (
    <Grid container className={styles.contentWrapper} mt={0} rowGap={2}>
      <Grid item xs={12}>
        <h3>Tax</h3>
      </Grid>
      <Grid item xs={12} className={styles.dataWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid className={styles.itemContainer}>
              <Grid>
                <h4>Tax</h4>
                <h5>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </h5>
              </Grid>
              <Grid>
                <CustomFormSwitch />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Tax;
