import React, { useState } from "react";
import styles from "./styles.module.css";
import { Grid } from "@mui/material";
import ButtonBlue from "../utils/CustomFields/ButtonBlue";
import TextFieldBGGray from "../utils/CustomFields/TextFieldBGGray";
import ExpenseService from "../../services/ExpenseService";

function AddExpenses({ closeDialog }) {
  const [description, setDescription] = useState(null);
  const [amount, setAmount] = useState(null);

  const handleAddExpense = async () => {
    try {
      if (!description || !amount) {
        return;
      }
      await ExpenseService.createExpense({ description, amount });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.popUp}>
      <Grid
        container
        className={styles.contentWrapper}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Grid item xs={12} mb={2}>
          <h3>Add Expenses</h3>
        </Grid>
        <Grid item xs={12} className={styles.contentArea} mb={2}>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Description</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter description"
                size="small"
                fullWidth
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container rowSpacing={1} marginBottom={2}>
            <Grid item xs={12}>
              <h5 style={{ color: "#8b8b8b" }}>Amount</h5>
            </Grid>
            <Grid item xs={12}>
              <TextFieldBGGray
                label="Enter amount"
                size="small"
                fullWidth
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <ButtonBlue
            style={{ padding: "7px 12px" }}
            className={styles.buttonGray}
            onClick={closeDialog}
          >
            Cancel
          </ButtonBlue>
          <ButtonBlue
            style={{ padding: "7px 12px" }}
            onClick={handleAddExpense}
          >
            Submit
          </ButtonBlue>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddExpenses;
