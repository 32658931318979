import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { STORAGE_KEYS, ROUTES } from "../../const";
import ShopContext from "../../context/ShopContext";
import validityExpired from "./validityExpired";

const PrivateRoute = ({ element: RouteComponent, ...rest }) => {
  const navigate = useNavigate()
  const { selectedShop } = useContext(ShopContext)
  const queryParams = new URLSearchParams(window.location.search);
  const paramsToken = queryParams.get("token");
  if (paramsToken) {
    localStorage.setItem(STORAGE_KEYS.TOKEN, paramsToken);
  }

  const token = paramsToken || localStorage.getItem(STORAGE_KEYS.TOKEN);

  if (token && validityExpired(selectedShop?.subscription_end_date)) {
    navigate(ROUTES.SELECT_SHOP)
  }

  return token ? <RouteComponent {...rest} /> : <Navigate to={ROUTES.LOGIN} />;
};

export default PrivateRoute;
