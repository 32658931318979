import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Grid, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../const";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CartItem from "../../utils/Cart/CartItem";
import ItemService from "../../../services/ItemService";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ButtonGray from "../../utils/CustomFields/ButtonGray";
import ButtonBlue from "../../utils/CustomFields/ButtonBlue";

function Paypage() {
  const [selecteditems, setSelectdedItems] = useState([]);
  const navigate = useNavigate();

  const moveToHomePage = () => {
    navigate(ROUTES.SALES);
  };

  useEffect(() => {
    const getItems = async () => {
      const items = await ItemService.getItems();
      const filteredItems = items.filter((item) => item?.category === 1925);
      setSelectdedItems(filteredItems);
    };
    getItems();
  }, []);

  return (
    <Grid container className={styles.container}>
      <Grid item xs={12} className={styles.titleContainer}>
        <Grid display="flex" alignItems="center">
          <ArrowBackRoundedIcon
            className={styles.icon}
            onClick={moveToHomePage}
          />
          <h2 className={styles.title}>Payment</h2>
        </Grid>
        <Grid className={styles.userInfo}>
          <Grid display="flex">
            <h4 className={styles.subTitle}>Customer name:</h4>
            <h4>Jeslin musthafa</h4>
          </Grid>
          <Grid display="flex" pt={0.3}>
            <h5 className={styles.subTitle}>Contact:</h5>
            <h5>+91 7591986068</h5>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.contentWrapper}>
        <Grid className={styles.wrapper}>
          <Grid container className={styles.wrapper}>
            <Grid item className={styles.cartWrapper}>
              <Grid className={styles.cartItemList}>
                <Grid container>
                  {selecteditems.map((cartItem) => (
                    <CartItem item={cartItem} />
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} className={styles.totalWrapper}>
                <Grid
                  container
                  rowSpacing={1}
                  className={styles.priceContainer}
                >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <h5 style={{ color: "#8b8b8b" }}>Items(8)</h5>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <CurrencyRupeeIcon className={styles.rupieeIcon} />
                        <h5>564</h5>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <h5 style={{ color: "#8b8b8b" }}>Discount</h5>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <CurrencyRupeeIcon className={styles.rupieeIcon} />
                        <h5>64</h5>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <h5 style={{ color: "#8b8b8b" }}>Tax</h5>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display={"flex"}
                        justifyContent={"flex-end"}
                      >
                        <CurrencyRupeeIcon className={styles.rupieeIcon} />
                        <h5>10</h5>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container style={{ color: "#0160c9" }}>
                  <Grid item xs={6}>
                    <h5>Sub Total</h5>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <CurrencyRupeeIcon className={styles.rupieeIcon} />
                    <h5>490</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={styles.dataWrapper}>
              <Grid container rowGap={2} height="100%">
                <Grid item xs={12} className={styles.dataContainer}>
                  <Grid display="flex" flexDirection="column">
                    <h5>Loyality card</h5>
                    <h4>2359395</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Loyality poing</h5>
                    <h4>233.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Credit limit</h5>
                    <h4>1000.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Credit balance</h5>
                    <h4>200.00</h4>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.dataContainer}>
                  <Grid display="flex" flexDirection="column">
                    <h5>Bill total</h5>
                    <h4>346.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Due</h5>
                    <h4>233.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Pre-paid</h5>
                    <h4>0.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Tender</h5>
                    <h4>0.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Bill discount</h5>
                    <h4>0.00</h4>
                  </Grid>
                  <Grid display="flex" flexDirection="column">
                    <h5>Charge</h5>
                    <h4>-43.50</h4>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={styles.paymentWrapper}>
                  <Grid container spacing={1} height="100%">
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Grid
                        className={`${styles.paymentType} ${styles.selected}`}
                      >
                        <h5>Cash</h5>
                        <h4>0.00</h4>
                      </Grid>
                      <Grid className={styles.paymentType}>
                        <h5>Card</h5>
                        <h4>0.00</h4>
                      </Grid>
                      <Grid className={styles.paymentType}>
                        <h5>Pre-paid</h5>
                        <h4>0.00</h4>
                      </Grid>
                      <Grid className={styles.paymentType}>
                        <h5>Phone pay</h5>
                        <h4>0.00</h4>
                      </Grid>
                      <Grid className={styles.paymentType}>
                        <h5>Wallet</h5>
                        <h4>0.00</h4>
                      </Grid>
                      <Grid className={styles.paymentType}>
                        <h5>Net banking</h5>
                        <h4>0.00</h4>
                      </Grid>
                      <Grid className={styles.paymentType}>
                        <h5>Bank</h5>
                        <h4>0.00</h4>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} height={"fit-content"}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6.9}>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, md: 2, lg: 3 }}
                          >
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>1</h4>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>2</h4>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>3</h4>{" "}
                              </div>
                            </Grid>

                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>4</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>5</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>6</h4>{" "}
                              </div>
                            </Grid>

                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>7</h4>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>8</h4>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>9</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>.</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div className={styles.button}>
                                <h4>0</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <ButtonGray
                                fullWidth
                                veriant="container"
                                className={styles.btnStyle}
                              >
                                Delete
                              </ButtonGray>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={0.5} display="flex" justifyContent='center'>
                          <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={4.4}>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, md: 2, lg: 3 }}
                          >
                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h4>+10</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h4>+20</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h4>+50</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h4>+100</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h4>+200</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h4>+500</h4>{" "}
                              </div>
                            </Grid>

                            <Grid item xs={6}>
                              <div className={styles.button}>
                                <h6>Payable</h6>
                                <h4>560.00</h4>{" "}
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <ButtonGray
                                fullWidth
                                veriant="container"
                                className={styles.btnStyle}
                              >
                                Clear
                              </ButtonGray>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <h4 className={styles.subTitle}>Pay balance:</h4>
                      <h4>-83.50</h4>
                      <h4 style={{ color: "#0160c9", margin: "0 7px" }}>
                        Add to credit
                      </h4>
                      <ButtonBlue sx={{ padding: "2px" }}>Credit</ButtonBlue>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ButtonGray
                            fullWidth
                            veriant="container"
                            className={styles.closeBtn}
                          >
                            Clear
                          </ButtonGray>
                        </Grid>
                        <Grid item xs={6}>
                          <ButtonBlue fullWidth sx={{ padding: "10px" }}>
                            Credit
                          </ButtonBlue>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Paypage;
